import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { useAuth } from '../../hooks';
import { M_ADD_FAVORITE_BUSINESS } from '../../graphql/user';
import { User, IAddFavorite } from '../../types/user';
import { BusinessRecord } from '../../types/business';
import FormRadioButton from '../form/ui/FormRadioButton';
import ErrorMessageComponent from '../form/ErrorMessageComponent';
import AddBoardForm from './AddBoardForm';
import LoadingIndicator from '../base/LoadingIndicator';
import styles from './add-favorite.module.sass';

const ValidationSchema = Yup.object().shape({
  boardId: Yup.string().nullable().required('Please Create/Select a Board'),
});

type Props = {
  business: BusinessRecord;
  onCompleted: () => void;
};

let userReload = false;

const AddFavoriteForm = ({ business, onCompleted }: Props) => {
  const formRef = useRef<any>();
  const { user, reloadUser } = useAuth();
  const [addToBoard, { loading, error }] = useMutation<User, IAddFavorite>(
    M_ADD_FAVORITE_BUSINESS,
    {
      onCompleted(data: any) {
        onCompleted();
      },
      onError(error: any) {
        console.log('add favorite error: ', error.message);
      },
    }
  );

  const handleFormSubmit = async () => {
    if (!formRef.current.isValid) {
      formRef.current.setSubmitting(false);
      return;
    }

    await addToBoard({
      variables: {
        userId: user.id,
        businessId: business.id,
        boardId: formRef.current.values.boardId,
      },
    });
  };

  const handleBoardAdded = () => {
    userReload = true;
    reloadUser();
  };

  useEffect(() => {
    if (!userReload) return;

    if (formRef && formRef.current) {
      formRef.current.setFieldValue(
        'boardId',
        user.data.favorites[user.data.favorites.length - 1].id
      );
    }
    userReload = false;
  }, [user]);

  const submitDisabled =
    user.data.favorites.length <= 0 ||
    !formRef.current ||
    (formRef.current && formRef.current.isSubmitting);

  return (
    <div className={`center column ${styles.container}`}>
      <Formik
        innerRef={formRef}
        initialValues={{
          boardId: null,
        }}
        validationSchema={ValidationSchema}
        onSubmit={handleFormSubmit}
      >
        <Form className={styles.form}>
          <div role="group" aria-labelledby="board-group">
            {user.data.favorites.map((board) => (
              <FormRadioButton
                key={`board-${board.id}`}
                name="boardId"
                label={board.label}
                value={board.id}
              />
            ))}
          </div>
          <ErrorMessage
            name="boardId"
            render={(msg) => <ErrorMessageComponent error={msg} />}
          />
        </Form>
      </Formik>
      <AddBoardForm onCompleted={handleBoardAdded} />
      <button
        type="submit"
        disabled={submitDisabled}
        className="button secondary no-arrow"
        onClick={() => formRef.current.submitForm()}
      >
        Save
      </button>
      {loading && <LoadingIndicator />}
      {error && <p className={styles.error}>{error.message}</p>}
    </div>
  );
};

export default AddFavoriteForm;
