import getConfig from 'next/config';
const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

export const setupRollbarUser = (userId: string) => {
  window.Rollbar?.configure({
    payload: {
      person: {
        id: userId,
      },
    },
  });
};

export const error = (error: any, info?: any) => {
  if (process.env.isLocal) {
    console.log('###### Rollbar Error #####');
    console.error(error, info);
  } else {
    // @ts-ignore
    error && window.Rollbar?.error(error, info || {});
  }
};

export const serverError = async (err: any, req: any) => {
  // Only require Rollbar and report error if we're on the server
  const ROLLBAR_ENVIRONMENT = publicRuntimeConfig.STAGE || 'prod';

  if (!process.browser) {
    const Rollbar = await import('rollbar');
    const rollbar = Rollbar.default.init({
      accessToken: serverRuntimeConfig.ROLLBAR_SERVER_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: ROLLBAR_ENVIRONMENT,
      },
    });
    err &&
      rollbar.error(err, req || {}, (rollbarError) => {
        if (rollbarError) {
          console.error('Rollbar error reporting failed:');
          console.error(rollbarError);
          return;
        }
        console.log('Reported error to Rollbar');
      });
  }
};
