import React from 'react';
import styles from './form-ui.module.sass';

const FormTermsLabel = () => (
  <div className={styles.label}>
    I agree to the{' '}
    <a
      className="text-link"
      href="/terms-and-conditions"
      target="_blank"
      rel="norel noreferrer"
    >
      Terms and Conditions
    </a>
  </div>
);

export default FormTermsLabel;
