//@flow
import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Routes } from '../../helpers/constants';
import { useSearchFilter, useWindowSize } from '../../hooks';
import styles from './carousel.module.sass';

enum Direction {
  NONE,
  LEFT,
  RIGHT,
}

type Props = {
  categories: Record<string, unknown>[];
  childBlock: (
    item: Record<string, unknown>,
    index: number,
    isTouch: boolean
  ) => JSX.Element;
  externalClassName?: string;
};

let startX = 0;
let previousX = 0;
let targetX = 0;
let lastDistance = 0;
let touchDelay = null;
let direction = null;

const Carousel = ({ categories, childBlock, externalClassName }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const categoriesRef = useRef<HTMLDivElement>();
  const { setFilterCategories } = useSearchFilter();
  const { windowWidth } = useWindowSize();
  const router = useRouter();
  const [swipeX, setSwipeX] = useState(0);
  const [maxX, setMaxX] = useState(0);
  const [isTouch, setIsTouch] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);

  useEffect(() => {
    const isTouchDevice =
      'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouch(isTouchDevice);
  }, []);

  useEffect(() => {
    if (!wrapperRef || !wrapperRef.current) return;
    if (!isTouch) wrapperRef.current.scrollTo(0, 0);
  }, [isTouch]);

  useEffect(() => {
    const isTouchDevice =
      'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouch(isTouchDevice);
    if (isTouchDevice) return;
    if (
      !wrapperRef ||
      !wrapperRef.current ||
      !categoriesRef ||
      !categoriesRef.current
    )
      return;
    const wrapperWidth = wrapperRef.current.getBoundingClientRect().width;
    const categoriesWidth = categoriesRef.current.clientWidth;

    setAnimating(true);
    setSwipeX(0);
    setMaxX(wrapperWidth - categoriesWidth);
    const animateTimeout = setTimeout(() => {
      setAnimating(false);
    }, 500);

    return () => clearTimeout(animateTimeout);
  }, [windowWidth, categories]);

  const handleArrowButton = (direction: Direction) => {
    if (!wrapperRef || !wrapperRef.current) return;
    const wrapperWidth = wrapperRef.current.getBoundingClientRect().width;
    const distance = wrapperWidth * 0.85;
    let newX;
    setAnimating(true);
    if (direction === Direction.LEFT) newX = swipeX - distance;
    if (direction === Direction.RIGHT) newX = swipeX + distance;

    if (newX > 0) newX = 0;
    if (newX < maxX) newX = maxX;
    setSwipeX(newX);

    setTimeout(() => {
      setAnimating(false);
    }, 500);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    if (isTouch) return;
    e.preventDefault();
    startX = e.clientX - swipeX;
    previousX = startX;
    setMouseDown(true);
    touchDelay = setTimeout(() => {
      clearTimeout(touchDelay);
      touchDelay = null;
    }, 150);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    if (isTouch) return;
    const shouldIgnore =
      e.target instanceof HTMLButtonElement && e.target.dataset['ignore'];
    if (shouldIgnore) return;
    e.preventDefault();
    if (!mouseDown) return;
    targetX = e.clientX;
    const distance = targetX - startX;
    direction =
      targetX < previousX
        ? Direction.LEFT
        : targetX > previousX
        ? Direction.RIGHT
        : Direction.NONE;
    let newX = distance;
    if (newX > 100) newX = 100;
    if (newX < maxX - 100) newX = maxX - 100;
    // if (newX > 0) newX = 0;
    // if (newX < maxX) newX = maxX;
    setSwipeX(newX);
    lastDistance = Math.abs(previousX - targetX);
    previousX = targetX;
  };

  const handleMouseLeave = (e: React.SyntheticEvent<EventTarget>) => {
    if (isTouch || !mouseDown) return;
    e.preventDefault();
    if (touchDelay && lastDistance < 10) {
      clearTimeout(touchDelay);
      touchDelay = null;
      startX = 0;
      lastDistance = 0;
      setMouseDown(false);
      if (!(e.target instanceof HTMLAnchorElement)) {
        return;
      }
      const category = e.target.dataset['category'];
      if (category) {
        setFilterCategories([category]);
        router.push(`${Routes.SHOP_BLACK_SEARCH}?sort=LATEST&cat=${category}`);
      }
      return;
    }

    if (mouseDown) {
      setAnimating(true);
      let newX = null;

      if (direction === Direction.LEFT) {
        newX = swipeX - lastDistance * 50;
      } else if (direction === Direction.RIGHT) {
        newX = swipeX + lastDistance * 50;
      }

      let delay = 0;
      if (newX) {
        if (newX > 0) newX = 100;
        if (newX < maxX) newX = maxX - 100;
        setSwipeX(newX);
        delay = 500;
      }

      // BOUNCE
      if (newX && (newX > 0 || newX < maxX)) {
        if (newX > 0) newX = 0;
        if (newX < maxX) newX = maxX;
        setTimeout(() => {
          setSwipeX(newX);
          setTimeout(() => {
            setAnimating(false);
          }, 500);
        }, delay);
      } else if (swipeX > 0 || swipeX < maxX) {
        if (swipeX > 0) newX = 0;
        if (swipeX < maxX) newX = maxX;
        setTimeout(() => {
          setSwipeX(newX);
          setTimeout(() => {
            setAnimating(false);
          }, 500);
        }, delay);
      } else {
        setTimeout(() => {
          setAnimating(false);
        }, 500);
      }
    }

    startX = 0;
    lastDistance = 0;
    setMouseDown(false);
  };

  return (
    <div
      ref={wrapperRef}
      className={`${styles.wrapper} ${isTouch ? styles.touch : ''} ${
        mouseDown ? styles.dragging : ''
      }
      ${externalClassName ? externalClassName : ''}
      `}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseLeave}
      onClick={handleMouseLeave}
    >
      {!isTouch && (
        <button
          className={`${styles.arrow} ${styles.left} ${
            swipeX >= 0 ? styles.disabled : ''
          }`}
          data-ignore={true}
          onClick={() => handleArrowButton(Direction.RIGHT)}
        />
      )}
      <div
        ref={categoriesRef}
        className={`${styles.categories} ${animating ? styles.animating : ''}`}
        style={{ transform: `translate3d(${isTouch ? 0 : swipeX}px, 0, 0)` }}
      >
        {categories &&
          categories.map((item, index) => childBlock(item, index, isTouch))}
      </div>
      {!isTouch && (
        <button
          className={`${styles.arrow} ${styles.right} ${
            swipeX <= maxX ? styles.disabled : ''
          }`}
          data-ignore={true}
          onClick={() => handleArrowButton(Direction.LEFT)}
        />
      )}
    </div>
  );
};

export default Carousel;
