import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { M_BUSINESS_PUBLISH_EDIT } from '../../graphql/business';
import {
  BusinessEditPublish,
  BusinessEditResponse,
  BusinessRecord,
} from '../../types/business';
import { PrismicBusinessDetail } from '../../types/prismic';
import { usePreview } from '../../hooks';
import LoadingIndicator from './LoadingIndicator';
import styles from './publish-bar.module.sass';
import Link from 'next/link';

type Props = {
  copy: PrismicBusinessDetail;
  business: BusinessRecord;
  needsEmailVerification: boolean;
  onUpdate: () => void;
};

const PublishBar = ({ copy, business, needsEmailVerification, onUpdate }: Props) => {
  if (!business || !copy) return null;
  const { previewMode, setPreviewMode } = usePreview();
  const [publishEnabled, setPublishEnabled] = useState(false);
  const [publishError, setPublishError] = useState('');

  const [editBusinessPublish, { loading, error }] = useMutation<
    BusinessEditResponse,
    BusinessEditPublish
  >(M_BUSINESS_PUBLISH_EDIT, {
    onCompleted(data: any) {
      if (data.editBusiness) {
        onUpdate();
      }
    },
    onError(error: any) {
      console.log('Error: ', error.message);
    },
  });

  const { isPublished, headerImage, website, about } = business.data;
  const {
    publish_hint,
    publish_button,
    unpublish_hint,
    unpublish_button,
    editor_button,
    preview_button,
  } = copy.data;

  useEffect(() => {
    setPublishEnabled(checkIfValid().isValid);
  }, [headerImage, website, about]);

  const produceProperInvalidMessage = (invalidItems) => {
    if (invalidItems.length === 1) return ' ' + invalidItems[0];

    let invalidMessage = invalidItems.reduce((acc, currentValue, currentIndex) => {
      let seperator = '';
      if (currentIndex > 0) {
        seperator = ',';
      }
      if (currentIndex != invalidItems.length - 1) return `${acc}${seperator} ${currentValue}`;
      return acc;
    }, '');

    return invalidMessage + ', and ' + invalidItems[invalidItems.length-1];
  }

  const checkIfValid = () => {
    let invalidItems = [];
    let addAddString = false;
    let invalidMessage = 'To publish your business page you need to';
    if (!website || !website.length) {
      invalidItems.push('a website url');
      addAddString = true;
    }
    if (!headerImage || !headerImage.length || !headerImage.includes('/uploads/')) {
      invalidItems.push('a thumbnail image');
      addAddString = true;
    }

    const imageOne =
      about && about.moodImages ? _.find(about.moodImages, { position: 1 }) : null;
    const imageTwo =
      about && about.moodImages ? _.find(about.moodImages, { position: 2 }) : null;
    const imageThree =
      about && about.moodImages ? _.find(about.moodImages, { position: 3 }) : null;

    const hasImageOne =
      imageOne &&
      imageOne.image &&
      imageOne.image.length > 0 &&
      imageOne.image.includes('/uploads/');

    const hasImageTwo =
      imageTwo &&
      imageTwo.image &&
      imageTwo.image.length > 0 &&
      imageTwo.image.includes('/uploads/');

    const hasImageThree =
      imageThree &&
      imageThree.image &&
      imageThree.image.length > 0 &&
      imageThree.image.includes('/uploads/');

    if (!hasImageOne && !hasImageTwo && !hasImageThree) {
      invalidItems.push('at least one header image');
      addAddString = true;
    }

    if (needsEmailVerification) {
      invalidItems.push('verify your email address');
    }

    if (invalidItems.length > 0) {
      if (addAddString) {
        invalidMessage += ' add';
      }
      invalidMessage += produceProperInvalidMessage(invalidItems) + '.';
    }

    return {
      isValid: invalidItems.length == 0,
      invalidMessage,
    };
  };

  const handleButtonClicked = () => {
    setPublishError('');

    if (!business.data.isPublished) {
      const { isValid, invalidMessage } = checkIfValid();
      if (!isValid) {
        setPublishError(invalidMessage);
        return;
      }
    }

    editBusinessPublish({
      variables: {
        id: business.id,
        isPublished: !business.data.isPublished,
      },
    });
  };

  const niceError = (error) => {
    if (error.message.includes('Forbidden')) {
      return 'We apparently hit snags, please sign out, sign in and then try again.';
    }
    return error.message;
  };

  return (
    <div className={styles.container}>
      <div className={`inner-width ${styles.inner}`}>
        {error ? (
          <p className={styles.error}>{niceError(error)}</p>
        ) : (
          <p className={styles.note}>
            {isPublished && unpublish_hint}
            {!isPublished && needsEmailVerification && <>
              Please <Link href="/account"><a>verify your email address</a></Link> before publishing
            </>}
            {!isPublished && !needsEmailVerification && <>
              {publish_hint}
            </>}
          </p>
        )}
        <div className={styles.buttons}>
          <button
            className={styles.edit}
            onClick={() => {
              setPublishError('');
              setPreviewMode(!previewMode);
            }}
          >
            {previewMode === true ? editor_button : preview_button}
          </button>
          <button
            className={`${styles.publish} ${!publishEnabled ? styles.disabled : ''}`}
            onClick={handleButtonClicked}
          >
            {loading ? (
              <LoadingIndicator inline button />
            ) : (
              <>{isPublished ? unpublish_button : publish_button}</>
            )}
          </button>
          {publishError && publishError.length > 0 && (
            <div className={styles.publisherror}>
              <p className={styles.error}>{publishError}</p>
              <button
                className={styles.close}
                onClick={() => {
                  setPublishError('');
                }}
              >
                <i />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublishBar;
