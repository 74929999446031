import React from 'react';
import { useCategories } from '../../../hooks';
import FormSelectField from '../../form/ui/FormSelectField';
import FormTextField from '../../form/ui/FormTextField';
import FormBooleanField from '../../form/ui/FormBooleanField';
import FormTextArrayField from '../../form/ui/FormTextArrayField';
import {
  CompanyProvidesOptions,
  ProductsMadeOptions,
} from '../../../helpers/constants';

interface Props {
  formField: {
    avgProductUnitPrice: any;
    companyProvide: any;
    products: any;
    ethicallySustainableMade: any;
    hasFactor: any;
    current: any;
    capacity: any;
    services: any;
    servicesOther: any;
    primary: any;
    primaryOther: any;
    secondary: any;
    secondaryOther: any;
    brandBio: any;
  };
  values: any;
}

const CategoriesForm = ({ formField, values }: Props) => {
  const { categories } = useCategories();
  const {
    avgProductUnitPrice,
    companyProvide,
    products,
    ethicallySustainableMade,
    hasFactor,
    current,
    capacity,
    services,
    servicesOther,
    primary,
    primaryOther,
    secondary,
    secondaryOther,
    brandBio,
  } = formField;

  const renderCompanyProvides = () => {
    return (
      <FormSelectField
        name={companyProvide.name}
        label={companyProvide.label}
        options={CompanyProvidesOptions}
        light
      />
    );
  };

  const renderProducts = () => {
    return (
      <>
        <FormSelectField
          isMulti={true}
          name={products.name}
          label={products.label}
          options={categories.data.products}
          light
        />
        <FormSelectField
          name={ethicallySustainableMade.name}
          label={ethicallySustainableMade.label}
          options={ProductsMadeOptions}
          light
        />
        <FormBooleanField
          name={hasFactor.name}
          label={hasFactor.label}
          selected={values[hasFactor.name]}
        />
        <FormTextArrayField
          name={current.name}
          label={current.label}
          addLabel="Add Additional Retailer"
          light
        />
        <FormTextField
          name={capacity.name}
          label={capacity.label}
          isBox={true}
          showCounter={true}
          light
        />
      </>
    );
  };
  const renderServices = () => {
    return (
      <>
        <FormSelectField
          isMulti={true}
          name={services.name}
          label={services.label}
          options={categories.data.services}
          light
        />
        {values[services.name] && values[services.name].find((el) => el.value === 'other') && (
          <FormTextField
            name={servicesOther.name}
            label={servicesOther.label}
            light
          />
        )}
      </>
    );
  };

  const providesItem = values[companyProvide.name];
  const showProducts =
    providesItem?.value === 'products' || providesItem?.value === 'both';
  const showServices =
    providesItem?.value === 'services' || providesItem?.value === 'both';


  return (
    <>
      <FormTextField
        name={brandBio.name}
        label={brandBio.label}
        isBox={true}
        light
      />
      <FormSelectField
        name={primary.name}
        label={primary.label}
        options={categories.data.categories_primary}
        light
      />
      {(values[primary.name] && values[primary.name].value === 'OTHER') && (
        <FormTextField
          name={primaryOther.name}
          label={primaryOther.label}
          light
        />
      )}
      <FormSelectField
        name={secondary.name}
        label={secondary.label}
        options={categories.data.categories_secondary}
        light
      />
      {(values[secondary.name] && values[secondary.name].value === 'OTHER') && (
        <FormTextField
          name={secondaryOther.name}
          label={secondaryOther.label}
          light
        />
      )}
      <FormTextField
        name={avgProductUnitPrice.name}
        label={avgProductUnitPrice.label}
        light
      />
      {renderCompanyProvides()}
      {showProducts && renderProducts()}
      {showServices && renderServices()}
    </>
  );
};

export default CategoriesForm;
