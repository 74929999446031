import React from 'react';
import styles from './icon.module.sass';

interface Props {
  dark?: boolean;
  brass?: boolean;
}

const ButtonArrow = ({ brass, dark }: Props) => (
  <svg width="28" height="17" viewBox="0 0 28 17">
    <path
      className={`${dark ? styles.dark : brass ? styles.brass : styles.light}`}
      d="M42.715,50.068l.762.734L52.3,42.3l-8.825-8.5-.762.734,7.516,7.242H24.3v1.049h25.93Z"
      transform="translate(-24.301 -33.802)"
      fill="#fff8f0"
    />
  </svg>
);

export default ButtonArrow;
