import React, { ReactElement } from 'react';

import { Colors } from '../../helpers/constants';
import styles from './two-images-with-blob.module.sass';

type PublicApi = {
  imgOne: string;
  imgTwo: string;
};

type Props = {
  children: (api: PublicApi) => ReactElement;
  className?: string;
  fillColor?: string;
};

const TwoImagesWithBlob = ({
  children,
  className,
  fillColor = Colors.NUTMEG,
}: Props) => (
  <div className={`${className} ${styles.images}`}>
    <svg
      className={styles.blob}
      viewBox="0 0 500 500"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
    >
      <path fill={fillColor}>
        <animate
          attributeName="d"
          dur="15s"
          repeatCount="indefinite"
          values="
              M384,275.5Q348,301,368,375.5Q388,450,326.5,410.5Q265,371,236.5,366Q208,361,155.5,370.5Q103,380,77.5,339.5Q52,299,94,260.5Q136,222,142.5,191Q149,160,172.5,134.5Q196,109,233,96Q270,83,320,79.5Q370,76,389,121.5Q408,167,414,208.5Q420,250,384,275.5Z;
              M417.5,286.5Q389,323,389,387.5Q389,452,329.5,432.5Q270,413,231,407.5Q192,402,141,397Q90,392,63.5,347.5Q37,303,65,257Q93,211,106.5,173Q120,135,152.5,106.5Q185,78,229.5,67Q274,56,325.5,60.5Q377,65,403,110.5Q429,156,437.5,203Q446,250,417.5,286.5Z;
              M384,275.5Q348,301,368,375.5Q388,450,326.5,410.5Q265,371,236.5,366Q208,361,155.5,370.5Q103,380,77.5,339.5Q52,299,94,260.5Q136,222,142.5,191Q149,160,172.5,134.5Q196,109,233,96Q270,83,320,79.5Q370,76,389,121.5Q408,167,414,208.5Q420,250,384,275.5Z;"
        />
      </path>
    </svg>
    <div className={styles.bottom}>
      {children({
        imgOne: styles.one,
        imgTwo: styles.two,
      })}
    </div>
  </div>
);

export default TwoImagesWithBlob;
