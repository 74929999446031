//@flow
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Business, BusinessRecord, SelectInput } from '../../types/business';
import { useMutation } from '@apollo/react-hooks';
import { useAuth, useCategories } from '../../hooks';
import {
  M_BUSINESS_EDIT,
  M_SIGNUP_BUSINESS,
  Q_BUSINESS_REGISTER,
} from '../../graphql/business';
import {
  BusinessRegisterStatuses,
  IntakeQuestions,
  NewsletterStates,
  UsStates,
} from '../../helpers/constants';
import businessFormModel from './business/model/businessFormModel';
import validationSchema from './business/model/validationSchema';
import omitDeep from 'omit-deep-lodash';

import {
  InfoOwnershipForm,
  ExtraInfoForm,
  InfoForm,
  EmailsInfoForm,
  CategoriesForm,
  GrowthForm,
} from './business';
import FormStepIndicator from '../form/ui/FormStepIndicator';
import FormBooleanField from '../form/ui/FormBooleanField';
import FormTermsLabel from '../form/ui/FormTermsLabel';
import LoadingIndicator from '../base/LoadingIndicator';
import styles from './register.module.sass';
import { useLazyQuery } from '@apollo/client';
import { handleNewsLetterSubmit } from '../../utils/newsletter';

enum FormSteps {
  INFO,
  EMAILS_INFO,
  EXTRA_INFO,
  OWNERSHIP,
  CATEGORIES,
  GROWTH,
}

const { formId, formField } = businessFormModel;

type Props = {
  newsletterLabel: string;
  onClose: () => void;
  onCompleted: (boolean) => void;
};

type FormData = {
  lastName: string;
  newsletterEmails: string[];
  companyCertified: boolean;
  companyCertifiedAgency: string;
  blackFounded: boolean;
  city: string;
  website: string;
  jobTitle: string;
  instagram: string;
  emails: string[];
  firstName: string;
  phone: string;
  name: string;
  blackFronted: boolean;
  blackOwned: boolean;
  indigenousOwned: boolean;
  majorityWomenOwned: boolean;
  stateCode?: SelectInput;
  state: string;
  yearFounded?: SelectInput;
  brandBio: string;

  avgProductUnitPrice: string;
  companyProvide?: SelectInput;
  ethicallySustainableMade?: SelectInput;
  hasFactor: boolean;
  current: string[];
  capacity: string;
  primary?: SelectInput;
  primaryOther: string;
  secondary?: SelectInput;
  secondaryOther: string;
  products: SelectInput[];
  services: SelectInput[];
  servicesOther: string;

  employeesNumber: string;
  lastAnnualRevenue?: SelectInput;
  projectedRevenue?: SelectInput;
  actualRevenue: string;
  raisedCapital?: SelectInput;
  capitalOrganizationsKind?: SelectInput;
  wantsInvestorsFunding: boolean;
  acceleratorPrograms: boolean;
  acceleratorProgramsDescription: string;
  interests: SelectInput[];
  intakeQuestion?: SelectInput;
  intakeQuestionOther: string;

  termsAndConditions: boolean;
  registerStatus?: string;
  newsletter: boolean;
};

const initialForm: FormData = {
  name: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  instagram: '',
  website: '',
  newsletterEmails: [],
  emails: [],
  phone: '',
  blackFounded: false,
  blackFronted: false,
  blackOwned: false,
  indigenousOwned: false,
  majorityWomenOwned: false,
  companyCertified: false,
  companyCertifiedAgency: '',
  city: '',
  stateCode: null,
  state: '',
  yearFounded: null,
  brandBio: '',

  primary: null,
  primaryOther: '',
  secondary: null,
  secondaryOther: '',
  avgProductUnitPrice: '',
  ethicallySustainableMade: null,
  products: [],
  hasFactor: false,
  current: [],
  capacity: '',
  services: [],
  servicesOther: '',

  employeesNumber: '',
  wantsInvestorsFunding: false,
  acceleratorPrograms: false,
  acceleratorProgramsDescription: '',
  interests: [],
  lastAnnualRevenue: null,
  projectedRevenue: null,
  actualRevenue: '',
  raisedCapital: null,
  capitalOrganizationsKind: null,
  intakeQuestion: null,
  intakeQuestionOther: '',

  termsAndConditions: false,
  newsletter: false,
};

const RegisterBusinessForm = ({
  newsletterLabel,
  onClose,
  onCompleted,
}: Props) => {
  const { user, reloadUser } = useAuth();
  const { categories } = useCategories();
  const [formInitialValues, setFormInitialValues] = useState(initialForm);
  const [activeStep, setActiveStep] = useState(FormSteps.INFO);
  const [newsletterState, setNewsletterState] = useState(NewsletterStates.FORM);
  const currentValidationSchema = validationSchema[activeStep];
  const formStepsLength = Object.keys(FormSteps).length / 2;
  const isLastStep = activeStep === formStepsLength - 1;
  const [fetchBusiness, { loading: loadingBusiness }] = useLazyQuery(
    Q_BUSINESS_REGISTER,
    {
      fetchPolicy: 'network-only',
      variables: {
        slug: user?.business?.data?.slug,
      },
      onCompleted(data: any) {
        let business = data.business as BusinessRecord;
        business = omitDeep(business, '__typename') as BusinessRecord;

        const primary = categories.data.categories_primary.find(
          (c) => c.value === business.data.categories.primary
        );
        const secondary = categories.data.categories_secondary.find(
          (c) => c.value === business.data.categories.secondary
        );
        setFormInitialValues({
          name: business.data.name,
          website: business.data.website,
          firstName: business.data.mainContact.firstName,
          lastName: business.data.mainContact.lastName,
          jobTitle: business.data.mainContact.jobTitle,
          instagram: business.data?.about?.instagram,
          newsletterEmails: business.data.mainContact.newsletterEmails,
          emails: business.data.mainContact.emails,
          phone: business.data.mainContact.phone,
          blackFounded: business.data.specs.ownership.blackFounded,
          blackFronted: business.data.specs.ownership.blackFronted,
          blackOwned: business.data.specs.ownership.blackOwned,
          indigenousOwned: business.data.specs.ownership.indigenousOwned,
          majorityWomenOwned: business.data.specs.ownership.majorityWomenOwned,
          companyCertified: business.data?.about?.companyCertified ?? false,
          companyCertifiedAgency:
            business.data?.about?.companyCertifiedAgency ?? '',
          city: business.data?.about?.city,
          stateCode: business.data?.about?.stateCode,
          state: business.data?.about?.state,
          yearFounded: business.data?.about?.yearFounded,
          brandBio: business.data?.about?.brandBio ?? '',

          avgProductUnitPrice: business.data.specs.finances.avgProductUnitPrice,
          companyProvide: business.data.specs.production.companyProvide,
          products: business.data.specs.production.products,
          primary,
          primaryOther: business.data?.categories?.primaryOther ?? '',
          secondary,
          secondaryOther: business.data?.categories?.secondaryOther ?? '',
          ethicallySustainableMade:
            business.data.specs.production.ethicallySustainableMade,
          hasFactor: business.data.specs.production.hasFactor,
          current: business.data.specs.retailers.current,
          capacity: business.data.specs.production.capacity,
          services: business.data.specs.production.services,
          servicesOther: business.data.specs.production.servicesOther,

          employeesNumber: business.data?.about?.employeesNumber,
          lastAnnualRevenue: business.data.specs.finances.lastAnnualRevenue,
          projectedRevenue: business.data.specs.finances.projectedRevenue,
          actualRevenue: business.data.specs.finances.actualRevenue,
          raisedCapital: business.data.specs.finances.raisedCapital,
          capitalOrganizationsKind:
            business.data.specs.finances.capitalOrganizationsKind,
          wantsInvestorsFunding:
            business.data.specs.finances.wantsInvestorsFunding,
          acceleratorPrograms:
            business.data.specs.finances.acceleratorPrograms ?? false,
          acceleratorProgramsDescription:
            business.data.specs.finances.acceleratorProgramsDescription ?? '',
          interests: business.data.specs.retailers.interests,
          intakeQuestion: business.data?.otherInfo?.intakeQuestion,
          intakeQuestionOther: business.data?.otherInfo?.intakeQuestionOther,

          termsAndConditions: true,
          newsletter: false,
        });
      },
    }
  );

  useEffect(() => {
    if (user?.business?.data?.slug) {
      fetchBusiness();
    } else {
      setFormInitialValues({
        ...formInitialValues,
        emails: [user.data.email],
      });
    }
  }, [user]);

  const [
    createBusiness,
    { loading: createLoading, error: createError },
  ] = useMutation<Business>(M_SIGNUP_BUSINESS, {
    onCompleted(data: any) {
      reloadUser();
      if (data && data.registerBusiness)
        onCompleted(newsletterState === NewsletterStates.ERROR);
    },
    onError(error: any) {
      console.log('Error:', error.message);
    },
  });

  const [
    editBusiness,
    { loading: editLoading, error: editError },
  ] = useMutation<Business>(M_BUSINESS_EDIT, {
    onCompleted(data: any) {
      reloadUser();
      if (data && data.editBusiness)
        onCompleted(newsletterState === NewsletterStates.ERROR);
    },
    onError(error: any) {
      console.log('Error:', error.message);
    },
  });

  const renderStepContent = (step, values) => {
    switch (step) {
      case FormSteps.INFO:
        return <InfoForm formField={formField} values={values} />;
      case FormSteps.EMAILS_INFO:
        return <EmailsInfoForm formField={formField} values={values} />;
      case FormSteps.EXTRA_INFO:
        return <ExtraInfoForm formField={formField} values={values} />;
      case FormSteps.OWNERSHIP:
        return <InfoOwnershipForm formField={formField} values={values} />;
      case FormSteps.CATEGORIES:
        return <CategoriesForm formField={formField} values={values} />;
      case FormSteps.GROWTH:
        return <GrowthForm formField={formField} values={values} />;
      default:
        return <div>Not Found</div>;
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    } else {
      onClose();
    }
  };

  const submitForm = async (values, actions) => {
    if (values.newsletter) {
      await handleNewsLetterSubmit({
        email: values.email,
        userType: 'Black-Business',
        firstName: user.data.firstName,
        lastName: user.data.lastName,
      });
      setNewsletterState(NewsletterStates.SUCCESS);
    }

    const data = {
      name: values.name,
      ownerId: user.id,
      website: values.website,
      mainContact: {
        firstName: values.firstName,
        lastName: values.lastName,
        emails: values.emails,
        newsletterEmails: values.newsletterEmails,
        phone: values.phone,
        jobTitle: values.jobTitle,
      },
      categories: {
        primary: values.primary ? values.primary.value : '',
        primaryOther: values.primaryOther,
        secondary: values.secondary ? values.secondary.value : '',
        secondaryOther: values.secondaryOther,
      },
      about: {
        employeesNumber: values.employeesNumber,
        instagram: values.instagram,
        companyCertified: values.companyCertified,
        companyCertifiedAgency: values.companyCertifiedAgency,
        city: values.city,
        stateCode: values.stateCode,
        state: values.state,
        yearFounded: values.yearFounded,
        brandBio: values.brandBio,
      },
      otherInfo: {
        intakeQuestion: values.intakeQuestion,
        intakeQuestionOther: values.intakeQuestionOther,
      },
      specs: {
        ownership: {
          blackOwned: values.blackOwned,
          blackFounded: values.blackFounded,
          blackFronted: values.blackFronted,
          indigenousOwned: values.indigenousOwned,
          majorityWomenOwned: values.majorityWomenOwned,
        },
        production: {
          companyProvide: values.companyProvide,
          products: values.products,
          ethicallySustainableMade: values.ethicallySustainableMade,
          hasFactor: values.hasFactor,
          capacity: values.capacity,
          services: values.services,
          servicesOther: values.servicesOther,
        },
        retailers: {
          current: values.current,
          interests: values.interests,
        },
        finances: {
          avgProductUnitPrice: values.avgProductUnitPrice,
          lastAnnualRevenue: values.lastAnnualRevenue,
          projectedRevenue: values.projectedRevenue,
          actualRevenue: values.actualRevenue,
          raisedCapital: values.raisedCapital,
          capitalOrganizationsKind: values.capitalOrganizationsKind,
          acceleratorPrograms: values.acceleratorPrograms,
          acceleratorProgramsDescription: values.acceleratorProgramsDescription,
          wantsInvestorsFunding: values.wantsInvestorsFunding,
        },
      },
    };

    if (user?.business) {
      delete data.ownerId;
      const editInput = {
        id: user.business.id,
        registerStatus: BusinessRegisterStatuses.COMPLETE,
        ...data,
      };
      await editBusiness({ variables: editInput });
    } else {
      await createBusiness({ variables: data });
    }

    actions.setSubmitting(false);
  };

  const handleFormSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      actions.setTouched({});
      actions.setSubmitting(false);
      setActiveStep(activeStep + 1);
    }
  };

  if (!user || (user?.business && loadingBusiness)) {
    return <div>Loading...</div>;
  }

  const error = createError || editError;

  const niceError = (error) => {
    if (error.message.includes('Forbidden')) {
      return 'We apparently hit snags, please sign out, sign in and then try again. If you continue to see issues, please contact us at info@15pp.org with a screenshot of your problem';
    }
    return error.message;
  };

  return (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize={true}
      validationSchema={currentValidationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ values, isSubmitting }) => (
        <Form id={formId} className={styles.form}>
          <FormStepIndicator length={formStepsLength} active={activeStep} />
          {renderStepContent(activeStep, values)}
          {isLastStep && (
            <>
              <FormBooleanField
                name="termsAndConditions"
                label={<FormTermsLabel />}
                selected={values.termsAndConditions}
                inline
                between
              />
              <FormBooleanField
                name="newsletter"
                label={newsletterLabel}
                selected={values.newsletter}
                inline
                between
              />
            </>
          )}
          <div
            className={`full-width text-center ${styles['submit-container']}`}
          >
            <button
              type="button"
              className={styles.back}
              onClick={() => handleBack()}
            >
              Back
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className={styles.next}
            >
              {isLastStep ? 'Submit' : 'Next'}
            </button>
          </div>
          {(editLoading || createLoading) && <LoadingIndicator />}
          {error && <p className={styles.error}>{niceError(error)}</p>}
        </Form>
      )}
    </Formik>
  );
};

export default RegisterBusinessForm;
