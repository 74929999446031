import * as React from 'react';

interface Props {
  children: string;
}

const StyleguideHeader = ({ children }: Props) => (
  <h3>
    <b>
      <i>{children}</i>
    </b>
  </h3>
);

export default StyleguideHeader;
