import React from 'react';
import styles from './form-ui.module.sass';

type Props = {
  length: number;
  active: number;
  small?: boolean;
  onItemClick?: (index: number) => void;
};

const FormStepIndicator = ({ small, length, active, onItemClick }: Props) => {
  const renderSteps = () => {
    const items = [];
    for (let i = 0; i < length; i += 1) {
      const item = (
        <li
          key={`${i}`}
          className={`${styles.step} ${i === active ? styles.active : ''}`}
          onClick={() => {
            onItemClick ? onItemClick(i) : null;
          }}
        />
      );
      items.push(item);
    }
    return items;
  };

  return (
    <ul className={`${styles.steps} ${small ? styles.small : ''}`}>
      {renderSteps()}
    </ul>
  );
};

export default FormStepIndicator;
