//@flow
import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { RichText } from 'prismic-reactjs';
import { UserTypes } from '../../types/form';
import { useAuth, useRegister } from '../../hooks';
import { Client } from '../../prismic-configuration';
import { ModalTypes } from '../../context/modalContext';
import { RegisterSteps } from '../../context/registerContext';
import { BusinessRegisterStatuses } from '../../helpers/constants';

import {
  RegisterBusinessForm,
  RegisterCorporationForm,
  RegisterTypeChoiceForm,
  RegisterUserForm,
} from '.';
import styles from './register.module.sass';
import { Routes } from '../../helpers/constants';

type Props = {
  // open: boolean;
  modalType: ModalTypes;
  onClose: () => void;
};

const RegisterModal = ({ modalType, onClose }: Props) => {
  const { user } = useAuth();
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [copy, setCopy] = useState(null);
  const [newsletterCopy, setNewsletterCopy] = useState(null);
  const [newsletterError, setNewsletterError] = useState(false);
  const { registerStep, setRegisterStep } = useRegister();

  // We need to set the completeDescription on an early render, before the register_status
  // is changed by the submission of the form. useRef enables us to store the
  // original value and ensure that it doesn't get updated by subsequent re-renders
  const completeDescription = useRef(null);

  useEffect(() => {
    loadCopy();
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  const loadCopy = async () => {
    const client = Client();
    const doc = await client.getSingle('register', {});
    setCopy(doc.data);
    const newsletter = await client.getSingle('newsletter', {});
    setNewsletterCopy(newsletter.data);
    setOpen(true);
  };

  const handleRouteChange = () => {
    setOpen(false);
  };

  const handleTypeChoiceMade = (type: UserTypes) => {
    if (user) {
      if (type === UserTypes.BUSINESS) {
        setRegisterStep(RegisterSteps.BUSINESS);
        // } else if (type === UserTypes.CORPORATION) {
        //   setRegisterStep(RegisterSteps.CORPORATION);
      }
    } else {
      setRegisterStep(RegisterSteps.USER);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'Escape':
        setOpen(false);
        // onClose();
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTransitionEnd = () => {
    if (!open) onClose();
  };

  const handleBusinessCompleted = (hasNewsletterError) => {
    setNewsletterError(hasNewsletterError);
    setRegisterStep(RegisterSteps.BUSINESS_COMPLETED);
  };

  // const handleCorporationCompleted = (hasNewsletterError) => {
  //   setNewsletterError(hasNewsletterError);
  //   setRegisterStep(RegisterSteps.CORPORATION_COMPLETED);
  // };

  if (!copy || !newsletterCopy) return null;

  const {
    user_header,
    hint_user,
    hint_business,
    hint_corporation,
    user_complete_description,
    business_header,
    business_update_header,
    business_description,
    business_complete_header,
    business_complete_description,
    business_needs_update_complete_description,
    business_complete_button,
    corporation_header,
    corporation_description,
    corporation_complete_header,
    corporation_complete_description,
    corporation_complete_button,
  } = copy;

  const { newsletter_error, newsletter_form_label } = newsletterCopy;

  // We set the completeDescription the first time we hit this and it doesn't get updated
  // by subsequent re-renders. This is so that when registerStatus is changed by the form
  // submission, we are still able to display the relevant completion message.
  if (!completeDescription.current) {
    const needsUpdate =
      user?.business?.data?.registerStatus ===
      BusinessRegisterStatuses.COMPLETED_NEEDS_UPDATE;
    completeDescription.current = needsUpdate
      ? business_needs_update_complete_description
      : business_complete_description;
  }

  const profileHeader =
    user?.business?.data?.registerStatus ===
    BusinessRegisterStatuses.COMPLETED_NEEDS_UPDATE
      ? business_update_header
      : business_header;

  return (
    <div
      className={`modal bg-brass ${open ? 'open' : ''}`}
      onKeyDown={handleKeyPress}
      onTransitionEnd={handleTransitionEnd}
    >
      <div className="modal-wrapper">
        <div className={`inner-width modal-close`}>
          <button className="btn-close light" onClick={handleClose}>
            <i />
          </button>
        </div>
        <div className={`modal-inner column center`}>
          {(registerStep === RegisterSteps.USER ||
            registerStep === RegisterSteps.TYPE_CHOICE) && (
            <div className={styles.header}>
              <RichText render={user_header} />
              {modalType === ModalTypes.REGISTER && (
                <RichText render={hint_user} />
              )}
              {modalType === ModalTypes.REGISTER_BUSINESS && (
                <RichText render={hint_business} />
              )}
              {/* {modalType === ModalTypes.REGISTER_CORPORATION && (
                <RichText render={hint_corporation} />
              )} */}
            </div>
          )}
          <div className={`full-width ${styles.step}`}>
            {/* {registerStep === RegisterSteps.TYPE_CHOICE && (
              <RegisterTypeChoiceForm onChoiceMade={handleTypeChoiceMade} />
            )} */}
            {registerStep === RegisterSteps.USER && (
              <RegisterUserForm
                newsletterLabel={newsletter_form_label}
                newsletterError={newsletter_error}
                confirmation={user_complete_description}
              />
            )}
            {registerStep === RegisterSteps.BUSINESS && (
              <div className={styles.container}>
                <div className={styles.header}>
                  <RichText render={profileHeader} />
                  <RichText render={business_description} />
                </div>
                <RegisterBusinessForm
                  newsletterLabel={newsletter_form_label}
                  onClose={() => {
                    onClose();
                  }}
                  onCompleted={handleBusinessCompleted}
                />
              </div>
            )}
            {registerStep === RegisterSteps.BUSINESS_COMPLETED && (
              <div className={styles.container}>
                <div className={styles.header}>
                  <RichText render={business_complete_header} />
                  <RichText render={completeDescription.current} />
                  {newsletterError && (
                    <div className="error">
                      <RichText render={newsletter_error} />
                    </div>
                  )}
                </div>
                <Link href={Routes.MY_ACCOUNT}>
                  <a className="button secondary">My Account</a>
                </Link>
              </div>
            )}
            {/* {registerStep === RegisterSteps.CORPORATION && (
              <div className={styles.container}>
                <div className={styles.header}>
                  <RichText render={corporation_header} />
                </div>
                <RichText render={corporation_description} />
                <RegisterCorporationForm
                  newsletterLabel={newsletter_form_label}
                  onClose={() => {
                    setRegisterStep(RegisterSteps.TYPE_CHOICE);
                  }}
                  onCompleted={handleCorporationCompleted}
                />
              </div>
            )} */}
            {/* {registerStep === RegisterSteps.CORPORATION_COMPLETED && (
              <div className={styles.container}>
                <div className={styles.header}>
                  <RichText render={corporation_complete_header} />
                </div>
                <RichText render={corporation_complete_description} />
                {newsletterError && (
                  <div className="error">
                    <RichText render={newsletter_error} />
                  </div>
                )}
                <Link href={Routes.SHOP_BLACK}>
                  <a className="button secondary">{corporation_complete_button}</a>
                </Link>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterModal;
