import React from 'react';
import FormSelectField from '../../form/ui/FormSelectField';
import FormTextField from '../../form/ui/FormTextField';
import {
  UsStates
} from '../../../helpers/constants';

interface Props {
  values?: any;
  formField: {
    instagram: any;
    website: any;
    phone: any;
    city: any;
    stateCode: any;
    state: any;
  };
}

const ExtraInfoForm = ({ formField, values }: Props) => {
  const { instagram, website, phone, city, stateCode, state } = formField;

  return (
    <>
      <FormTextField name={website.name} label={website.label} light />
      <FormTextField name={instagram.name} label={instagram.label} light />
      <FormTextField type="tel" name={phone.name} label={phone.label} light />
      <FormTextField name={city.name} label={city.label} light />
      <FormSelectField
        name={stateCode.name}
        label={stateCode.label}
        options={UsStates}
        light
      />
      {(values.stateCode?.value === 'ZZ') && (
        <FormTextField
          name={state.name}
          label={state.label}
          light />
      )}
    </>
  );
};

export default ExtraInfoForm;
