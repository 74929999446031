// @flow
import Link from 'next/link';
import React from 'react';
import { ModalTypes } from '../../context/modalContext';
import { useModal } from '../../hooks';
import { SubmenuType } from '../../types/prismic';
import styles from './footer.module.sass';

type Props = {
  submenu: SubmenuType;
  user: any;
};

const SubMenu = ({ submenu, user }: Props) => {
  const { setModalType } = useModal();

  if (submenu.items.length <= 0) return null;

  const { primary, items } = submenu;

  const renderItems = () => {
    const menu = items.map((item, index) => {
      if (item.show_modal !== ModalTypes.NONE) {
        if (item.show_modal === ModalTypes.LOGIN && user) return null;

        if (item.show_modal === ModalTypes.ACCESSIBILITY) {
          return (
            <li key={`${index}`} className={styles.item}>
              <a href="#" data-acsb-custom-trigger="true">
                {item.label}
              </a>
            </li>
          );
        }

        return (
          <li key={`${index}`} className={styles.item}>
            <button
              onClick={() => {
                setModalType(item.show_modal);
              }}
            >
              {item.label}
            </button>
          </li>
        );
      } else {
        const url = item.page
          ? item.page.uid === 'homepage'
            ? '/'
            : `/${item.page.uid}` || ''
          : '';
        return (
          <li key={`${index}`} className={styles.item}>
            <Link href={url}>
              <a>{item.label}</a>
            </Link>
          </li>
        );
      }
    });

    return menu;
  };

  return (
    <div className={styles.submenu}>
      <h5>{primary.submenu_header}</h5>
      <ul>{renderItems()}</ul>
    </div>
  );
};

export default SubMenu;
