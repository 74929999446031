import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import { NO_AUTHORIZATION_HEADER } from '../../helpers/apolloClient';
import { M_LOGIN, M_SIGNUP } from '../../graphql/user';
import { NewsletterStates } from '../../helpers/constants';
import { ILoginEmail, IToken, IUserNew, IUserNewEmail } from '../../types/user';
import FormTextField from '../form/ui/FormTextField';
import FormBooleanField from '../form/ui/FormBooleanField';
import FormTermsLabel from '../form/ui/FormTermsLabel';
import LoadingIndicator from '../base/LoadingIndicator';
import styles from './register.module.sass';
import { useAuth, useModal, useRegister } from '../../hooks';
import { ModalTypes } from '../../context/modalContext';
import { useRouter } from 'next/router';
import { handleNewsLetterSubmit } from '../../utils/newsletter';

const UserValidation = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(150, 'Too Long!')
    .required('Please enter your first name'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(150, 'Too Long!')
    .required('Please enter your last name'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
  password: Yup.string()
    .min(3, 'Too Short!')
    .max(150, 'Too Long!')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*?&@#$%.\\!/|,_-])[\w*?&@#$%.\\!\\/|,_-]{8,50}$/,
      'Must Contain One Uppercase, One Lowercase, One Number and one Special Character'
    )
    .required('Password is required'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  ),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    'The terms and conditions must be accepted.'
  ),
});

type Props = {
  newsletterLabel: string;
  newsletterError: RichTextBlock[];
  confirmation: RichTextBlock[];
};

const RegisterUserForm = ({
  newsletterLabel,
  newsletterError,
  confirmation, // @TODO: unused, clean up
}: Props) => {
  const { setUserToken } = useAuth();
  const router = useRouter();
  const [newsletterState, setNewsletterState] = useState(NewsletterStates.FORM);
  const { setModalType } = useModal();

  const [loginUser] = useMutation<IToken, ILoginEmail>(M_LOGIN, {
    onCompleted(data: any) {
      const token = data.login.token;
      if (token) setUserToken(token);
      setModalType(ModalTypes.NONE);
      router.push('/account');
    },
    onError() {
      setModalType(ModalTypes.LOGIN);
    },
  });

  const [createUser, { loading, error }] = useMutation<IUserNew, IUserNewEmail>(
    M_SIGNUP,
    {
      onError(error: any) {
        console.log('Error: ', error.message);
      },
    }
  );

  const handleFormSubmit = async (values: any) => {
    if (values.newsletter) {
      await handleNewsLetterSubmit({
        email: values.email,
        userType: 'Consumer',
        firstName: values.firstName,
        lastName: values.lastName,
      });
      setNewsletterState(NewsletterStates.SUCCESS);
    }

    await createUser({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
      },
      context: NO_AUTHORIZATION_HEADER,
    });

    await loginUser({
      variables: {
        email: values.email,
        password: values.password,
      },
      context: NO_AUTHORIZATION_HEADER,
    });
  };

  return (
    <div className={`column center ${styles.container}`}>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          passwordConfirmation: '',
          termsAndConditions: false,
          newsletter: false,
        }}
        validationSchema={UserValidation}
        onSubmit={handleFormSubmit}
      >
        {({ values }) => (
          <Form className={styles.form}>
            <FormTextField
              name="firstName"
              label="What's your first name?"
              usePlaceholder
              light
            />
            <FormTextField
              name="lastName"
              label="What's your last name?"
              usePlaceholder
              light
            />
            <FormTextField
              type="email"
              name="email"
              label="What's your email address?"
              usePlaceholder
              light
            />
            <FormTextField
              type="password"
              name="password"
              label="Choose a password"
              usePlaceholder
              light
            />
            <FormTextField
              type="password"
              name="passwordConfirmation"
              label="Confirm your password"
              usePlaceholder
              light
            />
            <FormBooleanField
              name="termsAndConditions"
              label={<FormTermsLabel />}
              selected={values.termsAndConditions}
              inline
              between
            />
            <FormBooleanField
              name="newsletter"
              label={newsletterLabel}
              selected={values.newsletter}
              inline
              between
            />
            <button
              type="submit"
              className={`full-width button secondary ${styles.submit}`}
            >
              Signup
            </button>
          </Form>
        )}
      </Formik>
      {loading && <LoadingIndicator />}
      {error && <p className={styles.error}>An error occurred</p>}
      {newsletterState === NewsletterStates.ERROR && (
        <div className="error">
          <RichText render={newsletterError} />
        </div>
      )}
    </div>
  );
};

export default RegisterUserForm;
