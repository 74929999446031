import React from 'react';
import { Field, useField } from 'formik';
import styles from './form-ui.module.sass';

type Props = {
  name: string;
  label: string;
  value: string;
};

const FormRadioButton = ({ name, label, value }: Props) => {
  const [field] = useField(name);

  return (
    <>
      <label
        className={`label ${styles.container} ${styles.radio} ${
          field.value === value ? styles.selected : ''
        }`}
        htmlFor={`radio-${value}`}
      >
        {label}
      </label>
      <Field
        className="sr"
        type="radio"
        id={`radio-${value}`}
        name={name}
        value={value}
      />
    </>
  );
};

export default FormRadioButton;
