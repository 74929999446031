import React, { useRef } from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { useAuth } from '../../hooks';
import { M_ADD_FAVORITE_BOARD } from '../../graphql/user';
import { User, ICreateBoard } from '../../types/user';
import FormTextField from '../form/ui/FormTextField';
import LoadingIndicator from '../base/LoadingIndicator';
import styles from './add-favorite.module.sass';

type Props = {
  onCompleted: () => void;
};

const AddFavoriteForm = ({ onCompleted }: Props) => {
  const formRef = useRef<any>();
  const { user } = useAuth();
  const [addFavoriteBoard, { loading, error }] = useMutation<User, ICreateBoard>(
    M_ADD_FAVORITE_BOARD,
    {
      onCompleted(data: any) {
        if (data && data.createBoard) {
          formRef.current.resetForm();
          onCompleted();
        }
      },
      onError(error: any) {
        console.log('add board error: ', error.message);
      },
    }
  );

  const handleFormSubmit = async (values: any) => {
    await addFavoriteBoard({
      variables: {
        userId: user.id,
        label: values.label,
        isPublic: false,
      },
    });
  };

  return (
    <div className={`center column ${styles.container}`}>
      <Formik
        innerRef={formRef}
        initialValues={{
          label: '',
        }}
        onSubmit={handleFormSubmit}
      >
        <Form className={`${styles.form} ${styles.single}`}>
          <div className="full-width row">
            <FormTextField
              name="label"
              label="Create New Board"
              maxLength={25}
              usePlaceholder={true}
              light
            />
            <button type="submit" className={styles.enter}>
              ↵
            </button>
          </div>
        </Form>
      </Formik>
      {loading && <LoadingIndicator />}
      {error && <p className={styles.error}>{error.message}</p>}
    </div>
  );
};

export default AddFavoriteForm;
