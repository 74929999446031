//@flow
import React from 'react';
import FormBooleanField from '../../form/ui/FormBooleanField';
import FormSelectField from '../../form/ui/FormSelectField';
import FormTextField from '../../form/ui/FormTextField';

interface Props {
  values?: any;
  formField: {
    blackFounded: any;
    blackFronted: any;
    yearFounded: any;
    blackOwned: any;
    indigenousOwned: any;
    majorityWomenOwned: any;
    companyCertified: any;
    companyCertifiedAgency: any;
  };
}

const InfoOwnershipForm = ({ values, formField }: Props) => {
  const {
    blackOwned,
    blackFronted,
    yearFounded,
    blackFounded,
    companyCertified,
    companyCertifiedAgency,
    indigenousOwned,
    majorityWomenOwned,
  } = formField;

  const buildYearOptions = () => {
    let currentYear = new Date().getFullYear();
    let startYear = 2010;
    let results = [];
    for (let i = 2010; i <= currentYear; i++) {
      const value = i === startYear ? `Before ${i}` : `${i}`;
      results.push({
        value,
        label: value,
      });
    }
    return results;
  };

  let yearOptions = buildYearOptions();

  return (
    <>
      <FormSelectField
        name={yearFounded.name}
        label={yearFounded.label}
        options={yearOptions}
        light
      />
      <FormBooleanField
        name={blackOwned.name}
        label={blackOwned.label}
        selected={values[blackOwned.name]}
      />
      <FormBooleanField
        name={blackFronted.name}
        label={blackFronted.label}
        selected={values[blackFronted.name]}
      />
      <FormBooleanField
        name={blackFounded.name}
        label={blackFounded.label}
        selected={values[blackFounded.name]}
      />
      <FormBooleanField
        name={indigenousOwned.name}
        label={indigenousOwned.label}
        selected={values[indigenousOwned.name]}
      />

      <FormBooleanField
        name={majorityWomenOwned.name}
        label={majorityWomenOwned.label}
        selected={values[majorityWomenOwned.name]}
      />
      <FormBooleanField
        name={companyCertified.name}
        label={companyCertified.label}
        selected={values[companyCertified.name]}
      />
      {values[companyCertified.name] === true && (
        <FormTextField
          name={companyCertifiedAgency.name}
          label={companyCertifiedAgency.label}
          light
        />
      )}
    </>
  );
};

export default InfoOwnershipForm;
