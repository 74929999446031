import * as Yup from 'yup';
import businessFormModel from './businessFormModel';
const {
  formField: {
    name,
    website,
    firstName,
    lastName,
    emails,
    phone,
    jobTitle,
    newsletterEmails,
    instagram,
    blackOwned,
    blackFounded,
    blackFronted,
    indigenousOwned,
    majorityWomenOwned,
    companyCertified,
    companyCertifiedAgency,
    city,
    stateCode,
    state,
    yearFounded,
    brandBio,

    primary,
    primaryOther,
    secondary,
    secondaryOther,
    avgProductUnitPrice,
    companyProvide,
    products,
    ethicallySustainableMade,
    hasFactor,
    current,
    capacity,
    services,
    servicesOther,

    employeesNumber,
    lastAnnualRevenue,
    projectedRevenue,
    actualRevenue,
    raisedCapital,
    capitalOrganizationsKind,
    wantsInvestorsFunding,
    acceleratorPrograms,
    acceleratorProgramsDescription,
    interests,
    intakeQuestion,
    intakeQuestionOther,
  },
} = businessFormModel;

export default [
  Yup.object().shape({
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [jobTitle.name]: Yup.string().required(`${jobTitle.requiredErrorMsg}`),
    [intakeQuestion.name]: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .typeError(intakeQuestion.requiredErrorMsg)
      .required(),
    [intakeQuestionOther.name]: Yup.string()
      .nullable()
      .when('intakeQuestion', (intakeQuestion) => {
        if (intakeQuestion?.value === 'other') {
          return Yup.string().required(
            `${intakeQuestionOther.requiredErrorMsg}`
          );
        }
      }),
  }),
  Yup.object().shape({
    [emails.name]: Yup.array()
      .of(
        Yup.string()
          .email(`${emails.invalidErrorMsg}`)
          .required(`${emails.requiredErrorMsg}`)
      )
      .min(1, `${emails.requiredErrorMsg}`),
    [newsletterEmails.name]: Yup.array().of(
      Yup.string()
        .email(`${newsletterEmails.invalidErrorMsg}`)
        .required(`${emails.invalidErrorMsg}`)
    ),
  }),
  Yup.object().shape({
    [phone.name]: Yup.string().required(`${phone.requiredErrorMsg}`),
    [website.name]: Yup.string()
      .url(`${website.invalidErrorMsg}`)
      .required(`${website.requiredErrorMsg}`),
    [instagram.name]: Yup.string(),
    [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
    [stateCode.name]: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .typeError(stateCode.requiredErrorMsg)
      .required(),
    [state.name]: Yup.string()
      .nullable()
      .when('stateCode', (stateCode) => {
        if (stateCode?.value === 'ZZ') {
          return Yup.string().required(`${state.requiredErrorMsg}`);
        }
      }),
  }),
  Yup.object().shape({
    [yearFounded.name]: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .typeError(yearFounded.requiredErrorMsg)
      .required(),
    [blackOwned.name]: Yup.boolean(),
    [blackFounded.name]: Yup.boolean(),
    [blackFronted.name]: Yup.boolean(),
    [indigenousOwned.name]: Yup.boolean(),
    [majorityWomenOwned.name]: Yup.boolean(),
    [companyCertified.name]: Yup.boolean(),
    [companyCertifiedAgency.name]: Yup.string().when(
      'companyCertified',
      (companyCertified) => {
        if (companyCertified === true) {
          return Yup.string().required(companyCertifiedAgency.requiredErrorMsg);
        }
      }
    ),
  }),
  Yup.object().shape({
    [brandBio.name]: Yup.string().required(brandBio.requiredErrorMsg),
    [primary.name]: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable(),
    [primaryOther.name]: Yup.string().when('primary', (primary) => {
      if (primary?.value === 'OTHER') {
        return Yup.string().required(primaryOther.requiredErrorMsg);
      }
    }),
    [secondary.name]: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable(),
    [secondaryOther.name]: Yup.string().when('secondary', (secondary) => {
      if (secondary?.value === 'OTHER') {
        return Yup.string().required(secondaryOther.requiredErrorMsg);
      }
    }),
    [avgProductUnitPrice.name]: Yup.number()
      .typeError(avgProductUnitPrice.invalidErrorMsg)
      .positive(avgProductUnitPrice.invalidErrorMsg)
      .required(`${avgProductUnitPrice.requiredErrorMsg}`),
    [companyProvide.name]: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .typeError(companyProvide.requiredErrorMsg)
      .required(),
    [products.name]: Yup.array().when('companyProvide', {
      is: (option) => option?.value === 'both' || option?.value === 'products',
      then: (schema) =>
        schema
          .of(
            Yup.object().shape({
              value: Yup.string(),
              label: Yup.string(),
            })
          )
          .min(1, products.requiredErrorMsg),
      otherwise: (schema) => schema.nullable(),
    }),
    [ethicallySustainableMade.name]: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .when('companyProvide', {
        is: (option) =>
          option?.value === 'both' || option?.value === 'products',
        then: (schema) =>
          schema
            .typeError(ethicallySustainableMade.requiredErrorMsg)
            .required(),
        otherwise: (schema) => schema.nullable(),
      }),
    [hasFactor.name]: Yup.boolean(),
    [current.name]: Yup.array().when('companyProvide', {
      is: (option) => option?.value === 'both' || option?.value === 'products',
      then: (schema) => schema.of(Yup.string()),
      otherwise: (schema) => schema.nullable(),
    }),
    [capacity.name]: Yup.string().when('companyProvide', {
      is: (option) => option?.value === 'both' || option?.value === 'products',
      then: (schema) => schema.required(capacity.requiredErrorMsg),
      otherwise: (schema) => schema.nullable(),
    }),
    [services.name]: Yup.array().when('companyProvide', {
      is: (option) => option?.value === 'both' || option?.value === 'services',
      then: (schema) =>
        schema
          .of(
            Yup.object().shape({
              value: Yup.string(),
              label: Yup.string(),
            })
          )
          .min(1, products.requiredErrorMsg),
      otherwise: (schema) => schema.nullable(),
    }),
    [servicesOther.name]: Yup.string().when('services', (services) => {
      if (services.find((el) => el.value === 'other')) {
        return Yup.string().required(servicesOther.requiredErrorMsg);
      }
    }),
  }),
  Yup.object().shape({
    [employeesNumber.name]: Yup.number()
      .typeError(employeesNumber.invalidErrorMsg)
      .positive(employeesNumber.invalidErrorMsg)
      .required(employeesNumber.requiredErrorMsg),
    [lastAnnualRevenue.name]: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .typeError(lastAnnualRevenue.requiredErrorMsg)
      .required(),
    [projectedRevenue.name]: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .typeError(projectedRevenue.requiredErrorMsg)
      .required(),
    [actualRevenue.name]: Yup.string().nullable(),
    [raisedCapital.name]: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .typeError(raisedCapital.requiredErrorMsg)
      .required(),
    [capitalOrganizationsKind.name]: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .when('raisedCapital', {
        is: (option) => option && option.value !== 'no',
        then: (schema) =>
          schema
            .typeError(capitalOrganizationsKind.requiredErrorMsg)
            .required(),
        otherwise: (schema) => schema.nullable(),
      }),
    [wantsInvestorsFunding.name]: Yup.boolean(),
    [acceleratorPrograms.name]: Yup.boolean(),
    [acceleratorProgramsDescription.name]: Yup.string().when(
      'acceleratorPrograms',
      {
        is: true,
        then: (schema) =>
          schema.required(acceleratorProgramsDescription.requiredErrorMsg),
        otherwise: (schema) => schema.nullable(),
      }
    ),
    [interests.name]: Yup.array().of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      })
    ),
    termsAndConditions: Yup.boolean().oneOf(
      [true],
      'The terms and conditions must be accepted.'
    ),
  }),
];
