import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks';
import UserLogin from './UserLogin';
import styles from './login.module.sass';

type Props = {
  shouldClose: boolean;
  onClose: () => void;
};

const LoginModal = ({ shouldClose, onClose }: Props) => {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 50);
  }, []);

  useEffect(() => {
    if (shouldClose) setOpen(false);
  }, [shouldClose]);

  useEffect(() => {
    if (user) {
      setOpen(false);
    }
  }, [user]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'Escape':
        onClose();
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTransitionEnd = () => {
    if (!open) onClose();
  };

  return (
    <div
      className={`modal ${open ? 'open' : ''}`}
      onKeyDown={handleKeyPress}
      onTransitionEnd={handleTransitionEnd}
    >
      <div className="modal-wrapper">
        <div className={`inner-width modal-close`}>
          <button className="btn-close" onClick={handleClose}>
            <i />
          </button>
        </div>
        <div className={`modal-inner column center`}>
          <div className={`full-width ${styles.step}`}>
            {!user && (
              <div className="column">
                <UserLogin showHeader={true} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
