import React from 'react';
import FormTextArrayField from '../../form/ui/FormTextArrayField';

interface Props {
  values?: any;
  formField: {
    newsletterEmails: any;
    emails: any;
  };
}

const EmailsInfoForm = ({ formField }: Props) => {
  const { emails, newsletterEmails } = formField;

  return (
    <>
      <FormTextArrayField
        name={emails.name}
        label={emails.label}
        fieldType="email"
        addLabel="Add Another"
        light
      />
      <FormTextArrayField
        name={newsletterEmails.name}
        label={newsletterEmails.label}
        fieldType="email"
        addLabel="Add Another"
        light
      />
    </>
  );
};

export default EmailsInfoForm;
