export enum UserTypes {
  CORPORATION = 'CORPORATION',
  BUSINESS = 'BUSINESS',
  CONSUMER = 'CONSUMER',
}

export interface FormProps {
  handleSubmit: (any) => void;
  setFieldValue: (any) => void;
  setFieldTouched: (any) => void;
  values: any;
  errors: any;
  touched: any;
}
