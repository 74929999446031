import React, { useEffect, useState } from 'react';
import { ModalTypes } from '../../context/modalContext';
import { useAuth, useModal } from '../../hooks';
import UserLogin from '../profile/UserLogin';
import EditBoardForm from './EditBoardForm';

type Props = {
  onClose: () => void;
  editType: ModalTypes;
};

const FavoriteModal = ({ onClose, editType }: Props) => {
  const [open, setOpen] = useState(false);
  const { user, reloadUser } = useAuth();
  const { favoriteBoard } = useModal();

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'Escape':
        setOpen(false);
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTransitionEnd = () => {
    if (!open) onClose();
  };

  // const handleBoardUpdated = () => {
  //   setViewState(ViewStates.COMPLETED);
  // };

  const handleEditCompleted = () => {
    reloadUser();
    setOpen(false);
  };

  return (
    <div
      className={`modal bg-nutmeg-alt ${open ? 'open' : ''}`}
      tabIndex={0}
      onKeyDown={handleKeyPress}
      onTransitionEnd={handleTransitionEnd}
    >
      <div className="modal-wrapper">
        <div className={`inner-width modal-close`}>
          <button className="btn-close light" onClick={handleClose}>
            <i />
          </button>
        </div>
        <div className={`modal-inner column center`}>
          <div className="full-width">
            {user || editType === ModalTypes.FAVORITE_SHARE ? (
              <div className="column center text-center">
                <EditBoardForm
                  board={favoriteBoard}
                  editType={editType}
                  onCancel={handleClose}
                  onCompleted={handleEditCompleted}
                />
              </div>
            ) : (
              <div className="column center">
                <h2 className="text-center">You{"'"}re not signed in</h2>
                <p>You need to be signed in to updated {favoriteBoard.label}.</p>
                <UserLogin />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoriteModal;
