// @flow
import React from 'react';
import styles from './wavy-box.module.sass';

type Props = {
  className: string;
  children: React.ReactNode;
};

const WavyBox = ({ className, children }: Props) => {
  return (
    <section className={`full-width ${className} ${styles.container}`}>
      {children}
    </section>
  );
};

export default WavyBox;
