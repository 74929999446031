//@flow
import React, { useEffect, useState } from 'react';
import { Client } from '../../prismic-configuration';
import styles from './popup-modal.module.sass';
import { Logo, LogoBlack } from '../base';

const PopUpModal = () => {
  const [copy, setCopy] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const loadCopy = async () => {
      const client = Client();
      const doc = await client.getSingle('popup-modal', {});
      const wait = doc?.data?.popup_delay ? doc?.data?.popup_delay * 1000 : 8000;
      setTimeout(() => setShowModal(true), wait);
      setCopy(doc.data);
    };

    loadCopy();
  }, []);

  if (!copy) return null;

  const {
    enable_popup,
    title,
    btn_text,
    btn_link,
    has_light_bg,
    left_image,
    partner_image,
  } = copy;

  const modalContainerColorClass = has_light_bg
    ? styles.modalContainerLight
    : styles.modalContainerDark;

  if (!enable_popup) return null;

  const closeModal = () => setShowModal(false);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'Escape':
        closeModal();
        break;
    }
  };

  return (
    <div
      className={`${styles.modalContainer} ${modalContainerColorClass} ${
        showModal ? styles.open : ''
      }`}
      onKeyDown={handleKeyPress}
    >
      <div className={styles.modal}>
        <div className={styles.contentContainer}>
          <div className={styles.closeContainer}>
            <button className={styles.btnClose} onClick={closeModal}>
              <i />
            </button>
          </div>
          <div className={styles.imageContainer}>
            <img alt={left_image.alt} src={left_image.url} />
          </div>
          <div className={styles.content}>
            <div className={styles.contentInner}>
              {has_light_bg ? (
                <>
                  <h3>{title}</h3>
                  {/*
                  <p>Sponsored by</p>
                  <div className={styles.popupImages}>
                    <Logo darkMode={false} collapsed={false} />
                    {partner_image.url && (
                      <>
                        <div className={styles.partnerImage}>
                          <img
                            alt={partner_image.alt}
                            src={partner_image.url}
                          />
                        </div>
                      </>
                    )}
                  </div>
                    */}
                  <a
                    className="button primary no-arrow invert"
                    href={btn_link.url}
                  >
                    {btn_text}
                  </a>
                </>
              ) : (
                <>
                  <LogoBlack />
                  <img alt={partner_image.alt} src={partner_image.url} />
                  <p>{title}</p>
                  <a className={styles.button} href={btn_link.url}>
                    {btn_text}
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpModal;
