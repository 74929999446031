import React from 'react';
import { ErrorMessage, Field, FieldArray, useField } from 'formik';
import ErrorMessageComponent from '../ErrorMessageComponent';
import styles from './form-ui.module.sass';

type Props = {
  name: string;
  label: string;
  addLabel: string;
  maxLength?: number;
  fieldType?: string;
  light?: boolean;
};

const FormTextArrayField = ({
  name,
  label,
  addLabel,
  maxLength,
  light,
  fieldType,
}: Props) => {
  const [field] = useField(name);
  const maxCount = maxLength ? maxLength : 10;
  const inputType = fieldType || 'text';
  return (
    <>
      <label className="label" htmlFor={name}>
        {label}
      </label>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            {field.value && field.value.length > 0 ? (
              field.value.map((_item, index) => (
                <>
                  <div
                    key={`${index}`}
                    className={`${styles.item} ${light ? styles.light : ''}`}
                  >
                    <Field type={inputType} name={`${name}.${index}`} />
                    {index < field.value.length - 1 && (
                      <button
                        type="button"
                        className={styles.button}
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        x
                      </button>
                    )}
                  </div>
                  {index === field.value.length - 1 &&
                    field.value.length < maxCount && (
                      <button
                        key={`${index}-add`}
                        type="button"
                        className={styles.add}
                        onClick={() => arrayHelpers.insert(field.value.length, '')}
                      >
                        + {addLabel}
                      </button>
                    )}
                </>
              ))
            ) : (
              <>
                <div
                  key={0}
                  className={`${styles.item} ${light ? styles.light : ''}`}
                >
                  <Field type="text" name={`${name}.${0}`} />
                </div>
                <button
                  type="button"
                  className={styles.add}
                  onClick={() => arrayHelpers.insert(field.value.length, '')}
                >
                  + {addLabel}
                </button>
              </>
            )}
          </>
        )}
      />
      <ErrorMessage
        name={name}
        render={(msg) => <ErrorMessageComponent error={msg} />}
      />
    </>
  );
};

export default FormTextArrayField;
