import gql from 'graphql-tag';

export const M_REGISTER_CORPORATION = gql`
  mutation registerCorporation(
    $name: String!
    $ownerId: String!
    $email: String!
    $phone: String!
  ) {
    registerCorporation(
      input: {
        name: $name
        ownerId: $ownerId
        mainContact: { email: $email, phone: $phone }
      }
    )
  }
`;
