//@flow
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Client } from '../../prismic-configuration';
import { useAuth, useModal, useWindowSize } from '../../hooks';
import { ModalTypes } from '../../context/modalContext';
import useScrollDirection, {
  ScrollDirection,
} from '../../hooks/useScrollDirection';
import useScrollPosition from '../../helpers/useScrollPosition';
import { ExclamationIcon } from '../icons';
import { Logo } from '.';
import { BusinessRegisterStatuses, Routes } from '../../helpers/constants';
import { BusinessRecord } from '../../types/business';
import PublishBar from './PublishBar';
import styles from './header.module.sass';

type Props = {
  darkMode: boolean;
  isPublishable: boolean;
  business?: BusinessRecord;
  onPublishChanged?: () => void;
};

const Header = ({
  darkMode,
  isPublishable,
  business,
  onPublishChanged,
}: Props) => {
  const { windowWidth } = useWindowSize();
  const scrollDirection = useScrollDirection({ thresholdPixels: 50 });
  const scrollPosition = useScrollPosition();
  // const { previewMode, setPreviewMode } = usePreview();
  const { user, logout } = useAuth();
  const { setModalType } = useModal();
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const [awardOpen, setAwardOpen] = useState(false);
  // const [searchOpen, setSearchOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [content, setContent] = useState(null);
  const [businessCopy, setBusinessCopy] = useState(null);
  const [awardMenuLeftEdge, setAwardMenuLeftEdge] = useState(0);
  // const [socialLinks, setSocialLinks] = useState(null);

  const { pathname } = router;
  const transparent = pathname === Routes.SHOP_BLACK;
  const isBusinessPage = pathname === Routes.BUSINESS;
  // const isHome = pathname === Routes.HOME;
  const isShopBlackSearch = pathname === Routes.SHOP_BLACK_SEARCH;
  const isCareerBoard = pathname === Routes.CAREER_BOARD;

  useEffect(() => {
    loadCopy();
    const handleRouteChange = () => {
      setMenuOpen(false);
      setAccountOpen(false);
      setAwardOpen(false);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  const loadCopy = async () => {
    const client = Client();
    const doc = await client.getSingle('menu', {});
    const businessDetail = await client.getSingle('business-detail', {});
    // const footer = await client.getSingle('footer', {});
    setContent(doc.data);
    setBusinessCopy(businessDetail);
    // setSocialLinks(footer.data.social_links);
  };

  useEffect(() => {
    const collapsed = scrollPosition > 100;
    setCollapsed(collapsed);
    if (collapsed && (isShopBlackSearch || isCareerBoard))
      setAccountOpen(false);
    if (collapsed && (isShopBlackSearch || isCareerBoard)) setAwardOpen(false);
  }, [scrollPosition]);

  useEffect(() => {
    // it takes a little bit to load our menu content, need to make sure that's happened
    const element = document.getElementById('nav-item-awards');
    if (element) {
      setAwardMenuLeftEdge(element.getBoundingClientRect().left);
    }
  }, [windowWidth, content]); // we watch content to ensure we refresh here when it loads

  const onMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const onAwardToggle = () => {
    setAwardOpen(!awardOpen);
  };

  // const onSearchToggle = () => {
  //   setSearchOpen(!searchOpen);
  // };

  const handleSignOut = () => {
    logout();
    setAccountOpen(false);
    setAwardOpen(false);
  };

  const needsEmailVerification = user && !user.data.enabled;

  const isMyAccount = pathname === Routes.MY_ACCOUNT && user;

  const isDesktopVersion = windowWidth >= 1024;
  const isMobileVersion = windowWidth < 1024;

  const renderAwardMenu = () => {
    if (!content) return null;
    const { user_logout_label, items_awards_menu } = content;

    return (
      <div
        className={`${styles.award} ${awardOpen ? styles.open : ''} ${
          !darkMode ? styles.dark : ''
        }`}
        style={{ left: awardMenuLeftEdge }}
      >
        <ul>
          {items_awards_menu.map((item, index) => (
            <>
              {index !== 0 ? <div className={styles.divider} /> : ''}
              <li key={`awards-${index}`}>
                <Link href={item.link}>
                  <a className={`${styles.link}`}>{item.label}</a>
                </Link>
              </li>
            </>
          ))}
        </ul>
      </div>
    );
  };

  const renderAccountMenu = () => {
    if (!content) return null;
    const {
      user_dashboard_label,
      // user_favorites_label,
      user_logout_label,
      // brand_pending_tooltip,
      // retailer_pending_tooltip,
    } = content;

    return (
      <div
        className={`${styles.account} ${accountOpen ? styles.open : ''} ${
          !darkMode ? styles.dark : ''
        }`}
      >
        <ul>
          <li key="account">
            <Link href="/account">
              <a
                className={`${styles.link} ${
                  isMyAccount ? styles.selected : ''
                }`}
              >
                {user_dashboard_label}
              </a>
            </Link>
          </li>
          <div className={styles.divider} />
          <li key="signout">
            <button
              className={`${styles.link} ${styles.signout}`}
              onClick={handleSignOut}
            >
              {user_logout_label}
            </button>
          </li>
        </ul>
      </div>
    );
  };

  const renderMenuItems = () => {
    if (!content) return null;

    const {
      businesses_search_label,
      account_label,
      // dashboard_label,
      // pledge_label,
      // about_label,
      login_label,
      // support_label,
      items,
    } = content;

    const renderinternalink = (index: any, label: any, routePath: any) => {
      return (
        <li key={`nav-${index}`}>
          <Link href={`/${routePath}`}>
            <a
              className={`${styles.link} ${
                pathname === `/${routePath}` ? styles.selected : ''
              }`}
            >
              {label}
            </a>
          </Link>
        </li>
      );
    };

    const renderExternalLink = (index: any, label: any, url: any) => {
      return (
        <li key={`nav-${index}`}>
          <a className={`${styles.link}`} href={url}>
            {label}
          </a>
        </li>
      );
    };

    const renderLink = (index: any, item: any) => {
      if (item.link.uid)
        return renderinternalink(index, item.label, item.link.uid);
      if (item.web_url.url)
        return renderExternalLink(index, item.label, item.web_url.url);
    };

    return (
      <>
        <ul className={styles.nav}>
          {items.map((item, index) => renderLink(index, item))}
          {renderinternalink(4, 'Grants', 'grants')}
        </ul>
        <ul className={`${styles.nav} ${styles.right}`}>
          <li key="shop-black">
            <Link href={Routes.SHOP_BLACK_SEARCH}>
              <a
                className={`${styles.link} ${
                  isShopBlackSearch ? styles.selected : ''
                }`}
              >
                {businesses_search_label}
              </a>
            </Link>
          </li>
          {user ? (
            <>
              {isMobileVersion ? (
                renderAccountMenu()
              ) : (
                <li
                  key="account-toggle"
                  className={`${darkMode ? styles.dark : ''}`}
                >
                  <button
                    className={`${styles.link} ${styles.accounttoggle} ${
                      accountOpen ? styles.open : ''
                    }`}
                    onClick={() => {
                      setAccountOpen(!accountOpen);
                      setAwardOpen(false);
                    }}
                  >
                    <span>{account_label}</span>
                    <i className={styles.close} />
                  </button>
                </li>
              )}
            </>
          ) : (
            <li key="login" className={styles.link}>
              <button onClick={() => setModalType(ModalTypes.LOGIN)}>
                {login_label}
              </button>
            </li>
          )}
        </ul>
      </>
    );
  };

  const logoDarkMode = darkMode || (isMobileVersion && menuOpen);

  return (
    <header
      className={`${styles.wrapper} ${isPublishable ? styles.publish : ''} ${
        darkMode ? styles.dark : ''
      } ${
        collapsed && (isCareerBoard || isShopBlackSearch) ? styles.hide : ''
      } ${isBusinessPage ? styles.solid : ''}`}
    >
      {isBusinessPage && isPublishable && (
        <PublishBar
          copy={businessCopy}
          business={business}
          needsEmailVerification={needsEmailVerification}
          onUpdate={onPublishChanged}
        />
      )}
      <div
        className={`${styles.header} ${
          scrollDirection === ScrollDirection.DOWN ? styles.down : ''
        } ${collapsed ? styles.collapsed : ''} ${
          isShopBlackSearch ? styles.search : ''
        } ${transparent ? styles.transparent : ''}`}
      >
        <nav className={styles.navbar}>
          <figure
            className={`${styles.logowrapper} ${
              collapsed ? styles.collapsed : ''
            }`}
          >
            <Link href="/">
              <a>
                <Logo darkMode={logoDarkMode} collapsed={collapsed} />
              </a>
            </Link>
          </figure>
          {/* {isBusinessPage && <BusinessNavigation collapsed={collapsed} />} */}
          {!isMobileVersion && renderAwardMenu()}
          {isMobileVersion ? (
            <>
              <div
                className={`column ${styles.modal} ${
                  menuOpen ? styles.open : ''
                }`}
              >
                {renderMenuItems()}
              </div>
              <button
                className={`center ${styles.menutoggle} ${
                  menuOpen ? styles.open : ''
                }`}
                onClick={onMenuToggle}
              >
                <i />
              </button>
            </>
          ) : (
            renderMenuItems()
          )}
          {user && !isMobileVersion && renderAccountMenu()}
        </nav>
      </div>
    </header>
  );
};

export default Header;

{
  /* <SearchModal open={searchOpen} onClose={() => setSearchOpen(false)} /> */
}
