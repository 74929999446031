import axios from 'axios';

type Values = {
  email: string;
  userType: string;
  firstName: string;
  lastName: string;
};

export const handleNewsLetterSubmit = async (values: Values) => {
  try {
    await axios.post('api/newsletter', {
      email: values.email,
      userType: values.userType,
      firstName: values.firstName,
      lastName: values.lastName,
    });
  } catch (e) {
    // ignored
  }
};
