//@flow
import React, { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { M_EDIT_FAVORITE_BOARD, M_DELETE_FAVORITE_BOARD } from '../../graphql/user';
import { useAuth } from '../../hooks';
import { ModalTypes } from '../../context/modalContext';
import { FavoriteBoards } from '../../types/user';
import FormBooleanField from '../form/ui/FormBooleanField';
import FormTextField from '../form/ui/FormTextField';
import styles from './edit-board.module.sass';

type Props = {
  board: FavoriteBoards;
  editType: ModalTypes;
  onCompleted: () => void;
  onCancel: () => void;
};

const EditBoardForm = ({ board, editType, onCancel, onCompleted }: Props) => {
  const { user, reloadUser } = useAuth();
  const { query } = useRouter();
  const inputCopyRef = useRef<HTMLInputElement>();
  const [formState, setFormState] = useState(editType);
  const [submitByCopy, setSubmitByCopy] = useState(false);
  const [copied, setCopied] = useState(false);
  const isOwner = user && user.id === query.user_id;

  const [editFavoriteBoard] = useMutation(M_EDIT_FAVORITE_BOARD, {
    onCompleted: () => {
      if (submitByCopy) {
        reloadUser();
        setSubmitByCopy(false);
      } else {
        onCompleted();
      }
    },
  });

  const [deleteFavoriteBoard] = useMutation(M_DELETE_FAVORITE_BOARD, {
    onCompleted: () => {
      onCompleted();
    },
  });

  const handleSubmit = (values) => {
    editFavoriteBoard({
      variables: {
        userId: user.id,
        boardId: board.id,
        label: values.label,
        isPublic: values.isPublic,
      },
    });
  };

  const handleBoardDelete = () => {
    deleteFavoriteBoard({
      variables: {
        userId: user.id,
        boardId: board.id,
      },
    });
  };

  const handleClose = () => {
    onCompleted();
  };

  const handleCopyLink = (values = null) => {
    if (values && board.isPublic !== values.isPublic) {
      setSubmitByCopy(true);
      editFavoriteBoard({
        variables: {
          userId: user.id,
          boardId: board.id,
          label: values.label,
          isPublic: values.isPublic,
        },
      });
    }

    if (inputCopyRef && inputCopyRef.current) {
      const copyText = inputCopyRef.current;
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');
      setCopied(true);
    }
  };

  const shareUrl =
    window !== undefined
      ? `https://${window.location.hostname}/favorites/${query.user_id}/${board.id}`
      : `https://15percentpledge.org/favorites/${query.user_id}/${board.id}`;

  return (
    <>
      {formState === ModalTypes.FAVORITE_EDIT && (
        <div className={styles.container}>
          <p className="uppercase">Edit </p>
          <h2>{board.label}</h2>
          <Formik
            initialValues={{
              label: board.label,
              isPublic: board.isPublic,
            }}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form className={styles.form}>
                <FormTextField name="label" label="Update board name" light />
                <FormBooleanField
                  inline
                  label="Make board public with shareable link"
                  name="isPublic"
                  selected={values.isPublic}
                />
                <div className={styles.copy}>
                  <input
                    ref={inputCopyRef}
                    type="text"
                    className={`light ${styles.link} ${
                      values.isPublic ? styles.enabled : ''
                    }`}
                    value={shareUrl}
                    disabled={!values.isPublic}
                    onChange={() => null}
                  />
                  <button
                    type="button"
                    className={styles.button}
                    disabled={!values.isPublic}
                    onClick={() => {
                      handleCopyLink(values);
                    }}
                  >
                    Copy
                  </button>
                </div>
                <p className={`${styles.copied} ${copied ? styles.show : ''}`}>
                  Link has been copied to your clipboard. Paste to share.
                </p>
                <div className={styles.submitcontainer}>
                  <button className="button secondary">save</button>
                </div>
              </Form>
            )}
          </Formik>
          <button
            className="button-inline"
            onClick={() => setFormState(ModalTypes.FAVORITE_DELETE)}
          >
            delete board
          </button>
        </div>
      )}
      {formState === ModalTypes.FAVORITE_DELETE && (
        <div className={styles.container}>
          <h2>Are you sure?</h2>
          <p>
            Deleting <b>{board.label.toUpperCase()}</b> cannot be undone.
          </p>
          <div className={styles.submitcontainer}>
            <button className="button secondary small" onClick={handleBoardDelete}>
              Delete
            </button>
          </div>
          <button
            type="button"
            className="button-inline"
            onClick={() => setFormState(ModalTypes.FAVORITE_EDIT)}
          >
            edit board
          </button>
        </div>
      )}
      {formState === ModalTypes.FAVORITE_SHARE && (
        <div className={styles.container}>
          <p className="uppercase">Share</p>
          <h2>{board.label}</h2>
          <div className={styles.copy}>
            <input
              ref={inputCopyRef}
              type="text"
              className={`light ${styles.link} ${styles.enabled}`}
              value={shareUrl}
              onChange={() => null}
            />
            <button
              type="button"
              className={styles.button}
              onClick={() => {
                handleCopyLink();
              }}
            >
              Copy
            </button>
          </div>
          <p className={`${styles.copied} ${copied ? styles.show : ''}`}>
            Link has been copied to your clipboard. Paste to share.
          </p>
          <div className={styles.submitcontainer}>
            <button className="button secondary small" onClick={handleClose}>
              Close
            </button>
          </div>
          {isOwner && (
            <button
              type="button"
              className="button-inline"
              onClick={() => setFormState(ModalTypes.FAVORITE_EDIT)}
            >
              edit board
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default EditBoardForm;
