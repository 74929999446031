import React, { useEffect, useRef, useState } from 'react';
import party from 'party-js';
import { useAuth, useModal } from '../../hooks';
import UserLogin from '../profile/UserLogin';
import AddFavoriteForm from './AddFavoriteForm';
import { Color } from 'party-js/lib/components';

enum ViewStates {
  ADD,
  COMPLETED,
}

const confettiColor = new Color(246, 234, 219); //new Color(19, 22, 35);

type Props = {
  onClose: () => void;
};

const FavoriteModal = ({ onClose }: Props) => {
  const confettiRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const [viewState, setViewState] = useState(ViewStates.ADD);
  const { user, reloadUser } = useAuth();
  const { favoriteBusiness } = useModal();

  //Heart Shape Confetti
  const heartPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  heartPath.setAttribute(
    'd',
    'M5.85930686,0.95358481 C5.79050686,0.95358481 5.72150686,0.95578481 5.65170686,0.96038481 C3.82550686,1.08158481 2.77530686,1.61698481 1.77690686,2.93658482 C0.382706856,4.77738482 0.971106856,7.28178482 1.80790686,8.39478482 C2.76290686,9.66478482 8.54270686,15.2327848 9.99530686,16.6261848 C11.4429069,15.2269848 17.2061069,9.63858482 18.1927069,8.38938482 C19.0589069,7.29278482 19.5661069,4.72098482 18.2177069,2.93958482 C17.2191069,1.62018481 16.1685069,1.08458481 14.3413069,0.96338481 C12.1127069,0.81558481 10.3979069,3.10718482 10.3807069,3.13038482 C10.2909069,3.25238482 10.1479069,3.32438482 9.99650686,3.32438482 L9.99630686,3.32438482 C9.84450686,3.32438482 9.70150686,3.25218482 9.61190686,3.12998482 C9.59530686,3.10758482 7.98670686,0.95358481 5.85930686,0.95358481 M9.99650686,17.7643848 C9.87790686,17.7643848 9.75930686,17.7203848 9.66710686,17.6325848 C9.36210686,17.3409848 2.18070686,10.4771848 1.04570686,8.96778482 C-0.160093145,7.36378482 -0.514093144,4.38198482 1.01670686,2.36098482 C2.19110686,0.80878481 3.47310686,0.14918481 5.58850686,0.00898481006 C7.66730686,-0.12781519 9.28930686,1.33098481 9.99670686,2.10638482 C10.7049069,1.33198481 12.3289069,-0.12581519 14.4045069,0.0119848101 C16.5209069,0.15238481 17.8031069,0.81178481 18.9779069,2.36418482 C20.6347068,4.55278482 20.0211068,7.61278482 18.9411069,8.98038482 C17.7767069,10.4543848 10.6307069,17.3387848 10.3271069,17.6311848 C10.2347069,17.7199848 10.1157069,17.7643848 9.99650686,17.7643848'
  );

  const heartShape = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  heartShape.setAttribute('viewBox', '0 0 20 18');
  heartShape.setAttribute('height', '20');
  heartShape.setAttribute('width', '18');
  heartShape.appendChild(heartPath);
  const s = new XMLSerializer();
  const heartString = s.serializeToString(heartShape);
  party.resolvableShapes['heart'] = heartString;

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'Escape':
        onClose();
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTransitionEnd = () => {
    if (!open) onClose();
  };

  const handleFavoriteAdded = () => {
    reloadUser();
    setViewState(ViewStates.COMPLETED);
    if (confettiRef && confettiRef.current) {
      party.confetti(confettiRef.current, {
        count: party.variation.range(80, 120),
        size: party.variation.range(0.8, 1.2),
        color: confettiColor,
        shapes: ['heart'],
      });
    }
  };

  return (
    <div
      className={`modal bg-rust ${open ? 'open' : ''}`}
      onKeyDown={handleKeyPress}
      onTransitionEnd={handleTransitionEnd}
    >
      <div className="modal-wrapper">
        <div className={`inner-width modal-close`}>
          <button className="btn-close light" onClick={handleClose}>
            <i />
          </button>
        </div>
        <div className={`modal-inner column center`}>
          <div className="full-width">
            <div ref={confettiRef} />
            {user ? (
              <>
                {viewState === ViewStates.ADD && (
                  <div className="column center text-center">
                    <h2 className="">Add to Favorites</h2>
                    <h5>{favoriteBusiness.data.name}</h5>
                    <AddFavoriteForm
                      business={favoriteBusiness}
                      onCompleted={handleFavoriteAdded}
                    />
                  </div>
                )}
                {viewState === ViewStates.COMPLETED && (
                  <div className="column center text-center">
                    <h2>{favoriteBusiness.data.name}</h2>
                    <h5>has been added to your Favorites</h5>
                    <button
                      className="button secondary no-arrow"
                      onClick={handleClose}
                    >
                      Ok
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="column center">
                <h2 className="text-center">You{"'"}re not signed in</h2>
                <p>
                  You need to be signed in to save {favoriteBusiness.data.name} to
                  your favorites.
                </p>
                <UserLogin light />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoriteModal;
