import React from 'react';
import FormTextField from '../../form/ui/FormTextField';
import FormBooleanField from '../../form/ui/FormBooleanField';
import FormSelectField from '../../form/ui/FormSelectField';
import { useCategories } from '../../../hooks';
import {
  AnnualRevenueOptions,
  CapitalKindOptions,
  CapitalOptions,
} from '../../../helpers/constants';

interface Props {
  values?: any;
  formField: {
    employeesNumber: any;
    lastAnnualRevenue: any;
    projectedRevenue: any;
    actualRevenue: any;
    raisedCapital: any;
    capitalOrganizationsKind: any;
    wantsInvestorsFunding: any;
    acceleratorPrograms: any;
    acceleratorProgramsDescription: any;
    interests: any;
  };
}

const InfoForm = ({ formField, values }: Props) => {
  const { categories } = useCategories();
  const {
    employeesNumber,
    lastAnnualRevenue,
    projectedRevenue,
    actualRevenue,
    raisedCapital,
    capitalOrganizationsKind,
    wantsInvestorsFunding,
    acceleratorPrograms,
    acceleratorProgramsDescription,
    interests,
  } = formField;

  const capital = values[raisedCapital.name];
  const showCapitalKind = capital && capital.value !== 'no';

  return (
    <>
      <FormTextField
        name={employeesNumber.name}
        label={employeesNumber.label}
        light
      />
      <FormSelectField
        name={lastAnnualRevenue.name}
        label={lastAnnualRevenue.label}
        options={AnnualRevenueOptions}
        light
      />
      <FormSelectField
        name={projectedRevenue.name}
        label={projectedRevenue.label}
        options={AnnualRevenueOptions}
        light
      />
      <FormTextField
        name={actualRevenue.name}
        label={actualRevenue.label}
        light
      />
      <FormSelectField
        name={raisedCapital.name}
        label={raisedCapital.label}
        options={CapitalOptions}
        light
      />
      {showCapitalKind && (
        <FormSelectField
          name={capitalOrganizationsKind.name}
          label={capitalOrganizationsKind.label}
          options={CapitalKindOptions}
          light
        />
      )}
      <FormBooleanField
        name={wantsInvestorsFunding.name}
        label={wantsInvestorsFunding.label}
        selected={values[wantsInvestorsFunding.name]}
      />
      <FormBooleanField
        name={acceleratorPrograms.name}
        label={acceleratorPrograms.label}
        selected={values[acceleratorPrograms.name]}
      />
      {values[acceleratorPrograms.name] && (
        <FormTextField
          name={acceleratorProgramsDescription.name}
          label={acceleratorProgramsDescription.label}
          isBox={true}
          showCounter={true}
          light
        />
      )}
      <FormSelectField
        isMulti={true}
        name={interests.name}
        label={interests.label}
        options={categories.data.retailers}
        light
      />
    </>
  );
};

export default InfoForm;
