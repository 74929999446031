import React from 'react';
import FormTextField from '../../form/ui/FormTextField';
import FormSelectField from '../../form/ui/FormSelectField';
import { IntakeQuestions } from '../../../helpers/constants';

interface Props {
  values?: any;
  formField: {
    name: any;
    firstName: any;
    lastName: any;
    jobTitle: any;
    intakeQuestion: any;
    intakeQuestionOther: any;
  };
}

const InfoForm = ({ formField, values }: Props) => {
  const {
    name,
    firstName,
    lastName,
    jobTitle,
    intakeQuestion,
    intakeQuestionOther,
  } = formField;

  return (
    <>
      <FormTextField name={name.name} label={name.label} light />
      <FormTextField name={firstName.name} label={firstName.label} light />
      <FormTextField name={lastName.name} label={lastName.label} light />
      <FormTextField name={jobTitle.name} label={jobTitle.label} light />
      <FormSelectField
        name={intakeQuestion.name}
        label={intakeQuestion.label}
        options={IntakeQuestions}
        light
      />
      {values[intakeQuestion.name] &&
        values[intakeQuestion.name].value === 'other' && (
          <FormTextField
            name={intakeQuestionOther.name}
            label={intakeQuestionOther.label}
            light
          />
        )}
    </>
  );
};

export default InfoForm;
