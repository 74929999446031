//@flow
import React, { useEffect, useState } from 'react';
import useScrollPosition from '../../helpers/useScrollPosition';
import styles from './back-to-top.module.sass';

const BackToTopButton = () => {
  const scrollPosition = useScrollPosition();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(scrollPosition > window.innerHeight * 2);
  }, [scrollPosition]);

  return (
    <button
      className={`${styles.button} ${visible ? styles.show : ''}`}
      onClick={() => window.scrollTo(0, 0)}
    >
      Back to Top
    </button>
  );
};

export default BackToTopButton;
