//@flow
import React, { useEffect, useState } from 'react';
import getConfig from 'next/config';
import axios from 'axios';
import * as Yup from 'yup';
import { RichText } from 'prismic-reactjs';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { getSocialIconForPlatform } from '../../helpers/utils';
import { NewsletterStates } from '../../helpers/constants';
import { Client } from '../../prismic-configuration';
import SubMenu from './SubMenu';
import ButtonArrow from '../buttons/ButtonArrow';
import ErrorMessageComponent from '../form/ErrorMessageComponent';
import LoadingIndicator from './LoadingIndicator';
import styles from './footer.module.sass';
import { useAuth } from '../../hooks';

export const { publicRuntimeConfig } = getConfig();

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
});

type Props = {
  show: boolean;
};

const Footer = ({ show }: Props) => {
  const { user } = useAuth();
  const [content, setContent] = useState(null);
  const [newsletter, setNewsletter] = useState(null);
  const [newsletterState, setNewsletterState] = useState(NewsletterStates.FORM);

  useEffect(() => {
    loadCopy();
  }, []);

  const loadCopy = async () => {
    const client = Client();
    const doc = await client.getSingle('footer', {});
    const newsletter = await client.getSingle('newsletter', {});
    setContent(doc.data);
    setNewsletter(newsletter.data);
  };

  if (!content || !newsletter) return null;

  const handleFooterSubmit = async (values) => {
    setNewsletterState(NewsletterStates.LOADING);
    try {
      await axios.post('api/newsletter', {
        email: values.email,
        userType: 'Consumer',
      });
      setNewsletterState(NewsletterStates.SUCCESS);
    } catch (e) {
      const { title } = e.response.data;
      if (title === 'Member Exists') {
        setNewsletterState(NewsletterStates.ALREADY_EXISTS);
      } else {
        setNewsletterState(NewsletterStates.ERROR);
      }
    }
  };

  const { body, header, social_links, copyright } = content;

  const {
    newsletter_email,
    // newsletter_button,
    newsletter_success,
    newsletter_exists,
    newsletter_error,
  } = newsletter;

  return (
    <footer className={`${styles.footer} ${show ? styles.show : ''}`}>
      <section className={styles.container}>
        <div className={styles.top}>
          <article className={styles.follow}>
            <RichText render={header} />
            <div className={styles.newsletter}>
              {newsletterState === NewsletterStates.FORM && (
                <Formik
                  validationSchema={ValidationSchema}
                  initialValues={{
                    email: '',
                  }}
                  onSubmit={handleFooterSubmit}
                >
                  <>
                    <Form className={styles.form}>
                      <section className={styles.inputs}>
                        <Field
                          type="email"
                          name="email"
                          placeholder={newsletter_email}
                          className={styles.input}
                        />
                        <button className={`${styles.join} button`} type="submit">
                          <ButtonArrow />
                        </button>
                      </section>
                    </Form>
                    <ErrorMessage
                      name="email"
                      render={(msg) => (
                        <ErrorMessageComponent
                          customClass={styles.error}
                          error={msg}
                        />
                      )}
                    />
                  </>
                </Formik>
              )}
              {newsletterState === NewsletterStates.LOADING && (
                <LoadingIndicator inline small />
              )}
              {newsletterState === NewsletterStates.SUCCESS && (
                <RichText render={newsletter_success} />
              )}
              {newsletterState === NewsletterStates.ALREADY_EXISTS && (
                <RichText render={newsletter_exists} />
              )}
              {newsletterState === NewsletterStates.ERROR && (
                <div className="error">
                  <RichText render={newsletter_error} />
                </div>
              )}
            </div>
            <ul className={styles.contact}>
              {social_links.map((item, index) => (
                <a
                  className={styles.social}
                  href={item.url}
                  key={`social-${index}`}
                  target="_blank"
                  rel="norel noreferrer"
                >
                  {getSocialIconForPlatform(item.platform)}
                </a>
              ))}
            </ul>
          </article>
          <nav className={styles.menu}>
            {body.map((item, index) => (
              <SubMenu key={`${index}`} submenu={item} user={user} />
            ))}
          </nav>
        </div>
        <section className={styles.bottom}>
          <p className={styles.copyright}>{copyright}</p>
        </section>
      </section>
    </footer>
  );
};

export default Footer;

{
  /* <div className={styles.menu}>
          <Link href={Routes.CONSUMER_COMMITMENT}>
            <a className={styles.link}>Consumer Pledge</a>
          </Link>
          <Link href={Routes.PLEDGED}>
            <a className={styles.link}>Pledge Takers</a>
          </Link>
          <Link href={Routes.SUPPORT_PLEDGE}>
            <a className={styles.link}>Donate</a>
          </Link>
          <Link href={Routes.FAQ}>
            <a className={styles.link}>FAQ</a>
          </Link>
          <a
            className={styles.link}
            href="mailto:info@15pp.org"
            target="_blank"
            rel="norel noreferrer"
          >
            Contact Us
          </a>
        </div> */
}
