import React, { useEffect, useState } from 'react';

import { RichText } from 'prismic-reactjs';
import { useMutation } from '@apollo/react-hooks';
import { useAuth } from '../../hooks';
import { Client } from '../../prismic-configuration';
import {
  M_BUSINESS_REGISTER_FOR_AWARD_EDIT,
  M_BUSINESS_REGISTER_FOR_AWARD_MODIFIED,
} from '../../graphql/business';
import {
  BusinessRegisterForAwardComplete,
  BusinessRegisterForAwardStarted,
} from '../../types/business';
import LoadingIndicator from '../base/LoadingIndicator';
import styles from './register.module.sass';
import { PrismicRegisterAward } from '../../types/prismic';
import { error as rollbarError } from '../../helpers/errors';

type Props = {
  onClose: () => void;
};

const RegisterBusinessForAwardModal = ({ onClose }: Props) => {
  const { user, reloadUser } = useAuth();
  const [copy, setCopy] = useState<PrismicRegisterAward>(null);
  const [Typeform, setTypeform] = useState(null);
  const [formDone, setFormDone] = useState(false);

  const beforeClose = () => {
    document.body.style.overflow = 'auto';
    onClose();
  };

  // ==== Handle getting these values somehow ...

  const businessId = user.business && user.business.id ? user.business.id : '';
  const email = user.data && user.data.email ? user.data.email : '';

  if (!businessId) {
    console.error('we do not have a business id, this should not be possible');
  }

  const typeFormHidden = {
    mongodb_id: businessId,
    email,
  };

  // if we use a link to leave this modal (our final submit button) we want the modal to close too
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    // we'll load typeform dynamically, so we don't get 13kb loaded on all pages
    const loadTypeform = async () => {
      const form = (await import('@typeform/embed-react')).Widget;
      setTypeform(form);
    };
    // handle loading data
    const loadCopy = async () => {
      const client = Client();
      const doc = await client.getByUID(
        'register_award',
        'register2023_award',
        {}
      );
      setCopy(doc);
    };

    loadCopy();
    loadTypeform();
  }, [businessId]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'Escape':
        beforeClose();
        break;
    }
  };

  const [
    updateBusinessAward,
    { loading, error },
  ] = useMutation<BusinessRegisterForAwardComplete>(
    M_BUSINESS_REGISTER_FOR_AWARD_EDIT,
    {
      onCompleted(data: any) {
        // update our local state so our dashboard state updates ...
        reloadUser();
        setFormDone(true); // show our conclusion screen
      },
      onError(error: any) {
        console.error('Error:', error.message);
        rollbarError(error, {
          email,
          businessId,
        });

        // doing this doesn't seem amazing, but might help with the snags we're seeing
        reloadUser();
        setFormDone(true); // show our conclusion screen
      },
    }
  );

  const [
    updateBusinessAwardProcess,
    // eslint-disable-next-line no-empty-pattern
    {
      /* updateLoading, updateError */
    },
  ] = useMutation<BusinessRegisterForAwardStarted>(
    M_BUSINESS_REGISTER_FOR_AWARD_MODIFIED,
    {
      ignoreResults: true,
      onError(error: any) {
        rollbarError(error, {
          email,
          businessId,
        });
        console.log('Error noting that the typeform loaded:', error.message);
      },
    }
  );
  const onTypeformLoaded = async () => {
    const lastModified = new Date().toISOString(); // @TODO: ensure this is in the format we need on the backend
    try {
      await updateBusinessAwardProcess({
        variables: {
          id: businessId,
          award2025LastModified: lastModified,
        },
      });
    } catch (e) {
      rollbarError(e, {
        email,
        businessId,
      });
      console.log(e);
    }
  };

  // there is nothing else available here, use the responseId to look up on the server if needed
  // https://github.com/Typeform/embed/blob/%40typeform/embed-v1.38.0/packages/embed/README.md#callbacks
  const handleFormSubmit = async ({ responseId }) => {
    try {
      await updateBusinessAward({
        variables: {
          id: businessId,
          typeformId: responseId,
        },
      });
    } catch (e) {
      rollbarError(e, {
        typeFormId: responseId,
        email,
        businessId,
      });
      console.log('Error handling the typeform completion:', e.message);
    }
  };

  if (!copy) return null;

  const {
    business_header,
    business_description,
    business_complete_header,
    business_complete_description,
    business_complete_button,
    business_typeform_id,
  } = copy.data;

  return (
    <div className={`modal bg-brass open`} onKeyDown={handleKeyPress}>
      <div className="modal-wrapper">
        <div className={`inner-width modal-close`}>
          <button className="btn-close light" onClick={beforeClose}>
            <i />
          </button>
        </div>
        <div className={`modal-inner column center`}>
          {formDone && (
            <>
              <div className={styles.container}>
                <div className={styles.header}>
                  <RichText render={business_complete_header} />
                </div>
                <RichText render={business_complete_description} />
                <button onClick={beforeClose} className="button secondary">
                  {business_complete_button}
                </button>
              </div>
            </>
          )}
          {!formDone && (
            <>
              <div className={styles.container}>
                <div className={styles.header}>
                  <RichText render={business_header} />
                  <RichText render={business_description} />
                </div>
              </div>
              <div className="full-width">
                {loading && <LoadingIndicator />}

                {!Typeform && <LoadingIndicator />}
                {/* https://github.com/Typeform/embed/blob/%40typeform/embed-v1.38.0/packages/embed/README.md#callbacks */}
                {!loading && (
                  <Typeform
                    id={business_typeform_id}
                    hideFooter={true}
                    hidden={typeFormHidden}
                    autoFocus={true}
                    onClose={beforeClose}
                    onReady={onTypeformLoaded}
                    onSubmit={handleFormSubmit}
                    style={{ width: '100%', height: '60vh' }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisterBusinessForAwardModal;
