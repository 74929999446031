//@flow
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { useAuth } from '../../hooks';
import { M_REGISTER_CORPORATION } from '../../graphql/corporation';
import { CorporationInput } from '../../types/corporation';
import { NewsletterStates } from '../../helpers/constants';
import FormTextField from '../form/ui/FormTextField';
import FormBooleanField from '../form/ui/FormBooleanField';
import FormTermsLabel from '../form/ui/FormTermsLabel';
import LoadingIndicator from '../base/LoadingIndicator';
import styles from './register.module.sass';
import { handleNewsLetterSubmit } from '../../utils/newsletter';

const CorporationValidation = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Please enter the company name')
    .required('Please enter the company name'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
  phone: Yup.string()
    .min(8, 'Please enter a valid phone number')
    .max(20, 'Please enter a valid phone number')
    .required('Please enter a phone number'),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    'The terms and conditions must be accepted.'
  ),
});

type Props = {
  newsletterLabel: string;
  onClose: () => void;
  onCompleted: (boolean) => void;
};

const RegisterCorporationForm = ({
  newsletterLabel,
  onClose,
  onCompleted,
}: Props) => {
  const { user, reloadUser } = useAuth();
  const [newsletterState, setNewsletterState] = useState(NewsletterStates.FORM);

  const [createCorporation, { loading, error }] = useMutation<CorporationInput>(
    M_REGISTER_CORPORATION,
    {
      onCompleted(data: any) {
        if (data && data.registerCorporation) {
          reloadUser();
          onCompleted(newsletterState === NewsletterStates.ERROR);
        }
      },
      onError(error: any) {
        console.log('Error:', error.message);
      },
    }
  );

  const handleFormSubmit = async (values) => {
    if (values.newsletter) {
      await handleNewsLetterSubmit({
        email: values.email,
        userType: 'Retailer',
        firstName: user.data.firstName,
        lastName: user.data.lastName,
      });
      setNewsletterState(NewsletterStates.SUCCESS);
    }

    await createCorporation({
      variables: {
        name: values.name,
        ownerId: user.id,
        email: values.email,
        phone: values.phone,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: user ? user.data.email : '',
        phone: '',
        termsAndConditions: false,
        newsletter: false,
      }}
      validationSchema={CorporationValidation}
      onSubmit={handleFormSubmit}
    >
      {({ values, isSubmitting }) => (
        <Form className={styles.form}>
          <FormTextField name="name" label="Company Name" maxLength={40} />
          <FormTextField name="email" label="Main Contact - Email Address" />
          <FormTextField
            name="phone"
            label="Main Contact - Phone Number"
            maxLength={20}
          />
          <FormBooleanField
            name="termsAndConditions"
            label={<FormTermsLabel />}
            selected={values.termsAndConditions}
            inline
            between
          />
          <FormBooleanField
            name="newsletter"
            label={newsletterLabel}
            selected={values.newsletter}
            inline
            between
          />
          <div className={`full-width ${styles['submit-container']}`}>
            <button type="button" className={styles.back} onClick={onClose}>
              back
            </button>
            <button type="submit" disabled={isSubmitting} className={styles.next}>
              {'submit'}
            </button>
          </div>
          {loading && <LoadingIndicator />}
          {error && <p className={styles.error}>{error.message}</p>}
        </Form>
      )}
    </Formik>
  );
};

export default RegisterCorporationForm;
